import { CategoryProduct } from "../../../models/categoryProduct";
import { ListRespone, Respone } from "../../../models/commom";
import { axiosClient } from "./axiosClient";

export const CategoryProductApis = {
  GetListCategoryProductionAdmin: (pageNumber: number, pageSize: number): Promise<ListRespone<CategoryProduct>> => {
    const url = `/CategoryProduction/GetListCategoryProductionAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListCategoryProductionClient: (pageNumber: number, pageSize: number): Promise<ListRespone<CategoryProduct>> => {
    const url = `/CategoryProduction/GetListCategoryProductionClient?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListCategoryProductionById: (IdCategoryProduction: string): Promise<ListRespone<CategoryProduct>> => {
    const url = `/CategoryProduction/GetListCategoryProductionById?IdCategoryProduction=${IdCategoryProduction}`;
    return axiosClient.get(url);
  },
  InsertCategoryProduction: (categoryProduct: CategoryProduct): Promise<Respone> => {
    const url = `/CategoryProduction/InsertCategoryProduction`;
    return axiosClient.post(url, categoryProduct);
  },
  UpdateCategoryProduction: (categoryProduct: CategoryProduct): Promise<Respone> => {
    const url = `/CategoryProduction/UpdateCategoryProduction`;
    return axiosClient.put(url, categoryProduct);
  },
  HideCategoryProductionByList: (listId: string[], check: boolean): Promise<ListRespone<CategoryProduct>> => {
    const url = `/CategoryProduction/HideCategoryProductionByList?check=${check}`;
    return axiosClient.put(url, listId);
  },
  DeleteCategoryProductionByList: (listId: string[]): Promise<ListRespone<CategoryProduct>> => {
    const url = `/CategoryProduction/DeleteCategoryProductionByList`;
    return axiosClient.delete(url, { data: listId });
  },
} as const;
