import {
  BarsOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  StopOutlined,
  UserAddOutlined,
  UserOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Space,
  Table,
  TableProps,
  Typography,
} from "antd";
import { ColumnsType, FilterValue } from "antd/lib/table/interface";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { User } from "../../../../../models/user";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { Modalcontent } from "../components/Modalcontent";
import {
  BlockListUserBoolean,
  DeleteUser,
  fetchDataUserList,
} from "../UserManageSlice";

const _UserManage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<{
    title: string;
    content: string;
  }>();
  const { loading, apiUsers, btnLoading, postLength } = useAppSelector(
    (state) => state.userManage
  );
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});

  useEffect(() => {
    dispatch(fetchDataUserList());
  }, [dispatch, postLength]);

  const handleChange: TableProps<User>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setFilteredInfo(filters);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const menuAction = (record: User) => {
    return (
      <Menu>
        <Menu.Item
          key='1'
          icon={<EditOutlined />}
          onClick={() => {
            navigate(`/admin/quan-ly-nguoi-dung/edit/${record.id}`);
          }}
        >
          Chỉnh sửa
        </Menu.Item>
      </Menu>
    );
  };

  const columns: ColumnsType<User> = [
    {
      title: "Họ và tên",
      dataIndex: "fullname",
      key: "fullname",
      filters: apiUsers.listPayload?.map((item) => ({
        text: item.fullname,
        value: item.fullname,
      })),
      filteredValue: filteredInfo?.fullname || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.fullname.startsWith(value),
      render: (text) => (
        <Typography.Link>
          {<UserOutlined />} {text}
        </Typography.Link>
      ),
    },
    {
      title: "Mã tài khoản",
      dataIndex: "userCode",
      key: "userCode",
      filters: apiUsers.listPayload?.map((item) => ({
        text: item.userCode,
        value: item.userCode,
      })),
      filteredValue: filteredInfo?.userCode || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.userCode.startsWith(value),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filters: apiUsers.listPayload?.map((item) => ({
        text: item.email,
        value: item.email,
      })),
      filteredValue: filteredInfo?.email || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.email.startsWith(value),
      render: (text) => (
        <Typography.Link href={`mailto:${text}`} target={"_blank"}>
          {text}
        </Typography.Link>
      ),
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      filters: apiUsers.listPayload?.map((item) => ({
        text: item.phone,
        value: item.phone,
      })),
      filteredValue: filteredInfo?.phone || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.phone.startsWith(value),
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      filters: apiUsers.listPayload?.map((item) => ({
        text: item.address,
        value: item.address,
      })),
      filteredValue: filteredInfo?.address || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.address.startsWith(value),
      render: (text, record) => (
        <Button
          icon={<EyeOutlined />}
          type='text'
          onClick={() => {
            setIsModalOpen(true);
            setModalContent({
              title: `Địa chỉ - ${record.fullname}`,
              content: text,
            });
          }}
        >
          Xem chi tiết
        </Button>
      ),
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      filters: apiUsers.listPayload?.map((item) => ({
        text: moment(item.createdDate).format("DD-MM-YYYY HH:mm:ss"),
        value: moment(item.createdDate).format("DD-MM-YYYY HH:mm:ss"),
      })),
      filteredValue: filteredInfo?.createdDate || null,
      filterSearch: true,
      onFilter: (value: any, record) =>
        record.createdDate.toString().startsWith(value),
      render: (text) => moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Trạng thái",
      dataIndex: "isLocked",
      key: "isLocked",
      render: (text) =>
        !text ? (
          <Typography.Text type='success'>Hoạt động</Typography.Text>
        ) : (
          <Typography.Text type='danger'>Không hoạt động</Typography.Text>
        ),
    },
    {
      title: "Thao tác",
      dataIndex: "Action",
      key: "Action",
      fixed: "right",
      render: (_, record) => (
        <Dropdown overlay={menuAction(record)} trigger={["click"]}>
          <BarsOutlined style={{ fontSize: 24 }} />
        </Dropdown>
      ),
      width: 10,
    },
  ];
  return (
    <div className='UserManage'>
      <Modalcontent
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalContent={modalContent}
      />

      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='mb-24'>
          <Card bordered={false} className='criclebox h-full'>
            <Typography.Title level={3}>Danh sách người dùng </Typography.Title>
            <Divider />
            <Space
              style={{
                marginBottom: 16,
              }}
            >
              <Button
                type='primary'
                onClick={() => {
                  setSelectedRowKeys([]);
                }}
                disabled={!(selectedRowKeys.length > 0)}
                loading={loading}
              >
                Bỏ chọn {selectedRowKeys.length} mục
              </Button>

              <Button
                onClick={(e) => navigate("/admin/quan-ly-nguoi-dung/new")}
                icon={<UserAddOutlined />}
                size='small'
                type='default'
              >
                Thêm người dùng mới
              </Button>
              <Popconfirm
                title='Bạn có chắc chắn không ?'
                okText='Có'
                disabled={!(selectedRowKeys.length > 0)}
                cancelText='Không'
                onConfirm={() =>
                  dispatch(
                    BlockListUserBoolean({
                      ListId: selectedRowKeys,
                      isBlock: false,
                    })
                  )
                }
              >
                <Button
                  type='primary'
                  disabled={!(selectedRowKeys.length > 0)}
                  ghost
                  loading={btnLoading}
                  icon={<CheckCircleOutlined />}
                >
                  Kích hoạt {selectedRowKeys.length} mục
                </Button>
              </Popconfirm>
              <Popconfirm
                title='Bạn có chắc chắn không ?'
                okText='Có'
                disabled={!(selectedRowKeys.length > 0)}
                cancelText='Không'
                onConfirm={() =>
                  dispatch(
                    BlockListUserBoolean({
                      ListId: selectedRowKeys,
                      isBlock: true,
                    })
                  )
                }
              >
                <Button
                  danger
                  type='default'
                  disabled={!(selectedRowKeys.length > 0)}
                  loading={btnLoading}
                  icon={<StopOutlined />}
                >
                  Khóa {selectedRowKeys.length} mục
                </Button>
              </Popconfirm>
              <Popconfirm
                title='Bạn có chắc chắn không ?'
                okText='Có'
                cancelText='Không'
                disabled={!(selectedRowKeys.length > 0)}
                onConfirm={() => {
                  dispatch(DeleteUser(selectedRowKeys));
                  setSelectedRowKeys([]);
                }}
              >
                <Button
                  danger
                  type='primary'
                  loading={btnLoading}
                  disabled={!(selectedRowKeys.length > 0)}
                  icon={<DeleteOutlined />}
                >
                  Xóa {selectedRowKeys.length} mục
                </Button>
              </Popconfirm>
            </Space>
            <Table
              scroll={{
                x: "auto",
              }}
              rowKey={(record) => record.id}
              columns={columns}
              rowSelection={rowSelection}
              dataSource={apiUsers.listPayload?.filter(
                (item) => item.fullname !== "Admin"
              )}
              onChange={handleChange}
              loading={loading}
              pagination={{
                total: apiUsers.totalElament,
                pageSize: 10,
                
                showSizeChanger: false,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export const UserManage = WithErrorBoundaryCustom(_UserManage);
