import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Career } from "../../../../models/career";
import { ListRespone } from "../../../../models/commom";
import { openNotificationWithIcon } from "../../../../units";
import { CareerApis } from "../../api/careerApis";

interface CareerState {
  loading: boolean;
  apis: ListRespone<Career>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: CareerState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as Career,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCareerAdmin = createAsyncThunk(
  "Career/GetListCareerAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CareerApis.GetListCareerAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListCareerById = createAsyncThunk(
  "Career/GetListCareerById",
  async (params: { IdCareer: string }, thunkAPI) => {
    try {
      const response = await CareerApis.GetListCareerById(params.IdCareer);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const InsertCareer = createAsyncThunk(
  "Career/InsertCareer",
  async (params: { career: Career }, thunkAPI) => {
    try {
      const response = await CareerApis.InsertCareer(params.career);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thêm tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const UpdateCareer = createAsyncThunk(
  "Career/UpdateCareer",
  async (params: { career: Career }, thunkAPI) => {
    try {
      const response = await CareerApis.UpdateCareer(params.career);
      if (response.success) {
        openNotificationWithIcon("success", "Thêm tin tức thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thêm tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteCareerByList = createAsyncThunk(
  "Career/DeleteCareerByList",
  async (params: { listId: string[] }, thunkAPI) => {
    try {
      const response = await CareerApis.DeleteCareerByList(params.listId);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa tin tức thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const HideCareerByList = createAsyncThunk(
  "Career/HideCareerByList",
  async (params: { listId: string[]; check: boolean }, thunkAPI) => {
    try {
      const response = await CareerApis.HideCareerByList(params.listId, params.check);
      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const CareerSlice = createSlice({
  name: "Career",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCareerAdmin
    [GetListCareerAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCareerAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCareerAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListCareerById
    [GetListCareerById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCareerById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
      state.apis.listPayload = [];
    },
    [GetListCareerById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertCareer
    [InsertCareer.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertCareer.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertCareer.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateCareer
    [UpdateCareer.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateCareer.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [UpdateCareer.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteCareerByList
    [DeleteCareerByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteCareerByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteCareerByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region HideCareerByList
    [HideCareerByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [HideCareerByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [HideCareerByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default CareerSlice.reducer;
