import { CategoryAboutUs } from "../../../models/categoryAboutUs";
import { ListRespone, Respone } from "../../../models/commom";
import { axiosClient } from "./axiosClient";
import { aboutUs } from "../../../models/aboutUs";

export const CategoryAboutUsApis = {
  GetListCategoryAboutUsAdmin: (pageNumber: number, pageSize: number): Promise<ListRespone<CategoryAboutUs>> => {
    const url = `/CategoryAboutUs/GetListCategoryAboutUsAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListCategoryAboutUsClient: (pageNumber: number, pageSize: number): Promise<ListRespone<CategoryAboutUs>> => {
    const url = `/CategoryAboutUs/GetListCategoryAboutUsClient?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListCategoryAboutUsForInsertAboutUs: (pageSize: number, pageNumber: number): Promise<ListRespone<aboutUs>> => {
    const url = `/CategoryAboutUs/GetListCategoryAboutUsForInsertAboutUs?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListCategoryAboutUsAppBar: (pageSize: Number, pageNumber: number): Promise<ListRespone<CategoryAboutUs>> => {
    const url = `/CategoryAboutUs/GetListCategoryAboutUsAppBar?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListCategoryAboutUsById: (IdCategoryAboutUs: string): Promise<ListRespone<CategoryAboutUs>> => {
    const url = `/CategoryAboutUs/GetListCategoryAboutUsById?IdCategoryAboutUs=${IdCategoryAboutUs}`;
    return axiosClient.get(url);
  },
  InsertCategoryAboutUs: (categoryAboutUs: CategoryAboutUs): Promise<Respone> => {
    const url = `/CategoryAboutUs/InsertCategoryAboutUs`;
    return axiosClient.post(url, categoryAboutUs);
  },
  UpdateCategoryAboutUs: (categoryAboutUs: CategoryAboutUs): Promise<Respone> => {
    const url = `/CategoryAboutUs/UpdateCategoryAboutUs`;
    return axiosClient.put(url, categoryAboutUs);
  },
  HideCategoryAboutUsByList: (listId: string[], check: boolean): Promise<ListRespone<CategoryAboutUs>> => {
    const url = `/CategoryAboutUs/HideCategoryAboutUsByList?check=${check}`;
    return axiosClient.put(url, listId);
  },
  DeleteCategoryAboutUsByList: (listId: string[]): Promise<ListRespone<CategoryAboutUs>> => {
    const url = `/CategoryAboutUs/DeleteCategoryAboutUsByList`;
    return axiosClient.delete(url, { data: listId });
  },
} as const;
