import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import { ListRespone } from "../../../../models/commom";
import { User } from "../../../../models/user";
import { openNotificationWithIcon } from "../../../../units";
import { UserApis } from "../../api/UserApis";
//add type loading for initialState
interface UserManageState {
  loading: boolean;
  apiUsers: ListRespone<User>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: UserManageState = {
  loading: false,
  apiUsers: {
    listPayload: [],
    payload: {} as User,
  },
  btnLoading: false,
  postLength: 0,
};

export const fetchDataUserList = createAsyncThunk(
  "userManage/fetchDataUserList",
  async (params, thunkAPI) => {
    try {
      const response = await UserApis.GetListUser();
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách người dùng thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const GetUserById = createAsyncThunk(
  "userManage/GetUserById",
  async (params: string, thunkAPI) => {
    try {
      const response = await UserApis.GetUserById(params);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy thông tin người dùng thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const DeleteUser = createAsyncThunk(
  "userManage/DeleteUser",
  async (params: React.Key[], thunkAPI) => {
    try {
      const response = await UserApis.RemoveUserByList(params);

      if (response.success) {
        openNotificationWithIcon("success", "Xóa người dùng thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa người dùng thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const CreateUser = createAsyncThunk(
  "userManage/CreateUser",
  async (params: any, thunkAPI) => {
    try {
      const response: any = await UserApis.CreateUser(params);
      if (response.data.success) {
        openNotificationWithIcon("success", "Tạo người dùng thành công", response.data.message);
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        openNotificationWithIcon("warning", "Tạo người dùng thất bại", response?.data?.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const UpdateUser = createAsyncThunk(
  "userManage/UpdateUser",
  async (params: any, thunkAPI) => {
    try {
      const response: any = await UserApis.UpdateUser(params);
      if (response.data.success) {
        openNotificationWithIcon(
          "success",
          "Cập nhật người dùng thành công",
          response.data.message
        );
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        openNotificationWithIcon(
          "warning",
          "Cập nhật người dùng thất bại",
          response?.data?.message
        );
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const ChangePasswordUser = createAsyncThunk(
  "userManage/ChangePasswordUser",
  async (params: { email: string; newPassword: string }, thunkAPI) => {
    try {
      const response = await UserApis.ForgotPassword(params.email, params.newPassword);
      if (response.success) {
        openNotificationWithIcon("success", "Đổi mật khẩu thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Đổi mật khẩu thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const BlockListUserBoolean = createAsyncThunk(
  "userManage/BlockListUser",
  async (params: { ListId: React.Key[]; isBlock: boolean }, thunkAPI) => {
    try {
      const response = await UserApis.LockUserAccountByList(params.ListId, params.isBlock);

      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userManageSlice = createSlice({
  name: "userManage",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDataUserList.pending.type]: (state) => {
      state.loading = true;
    },
    [fetchDataUserList.fulfilled.type]: (state, action: PayloadAction<ListRespone<User>>) => {
      state.loading = false;
      state.apiUsers.listPayload = action.payload.listPayload;
      state.apiUsers.totalElament = action.payload.totalElament;
      state.apiUsers.totalPages = action.payload.totalPages;
    },
    [fetchDataUserList.rejected.type]: (state) => {
      state.loading = false;
    },
    [GetUserById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetUserById.fulfilled.type]: (state, action: PayloadAction<ListRespone<User>>) => {
      state.loading = false;
      state.apiUsers.payload = action.payload.payload;
    },
    [BlockListUserBoolean.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [BlockListUserBoolean.fulfilled.type]: (state) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteUser.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteUser.fulfilled.type]: (state) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteUser.rejected.type]: (state) => {
      state.btnLoading = false;
    },
  },
});

export default userManageSlice.reducer;
