import { globalVariable } from "../../../globalVariable";
import {
  ListRespone,
  ListResponeFormData,
  PartialRequiredId,
} from "../../../models/commom";
import { News } from "../../../models/news";
import { axiosClient } from "./axiosClient";

export const NewsApis = {
  GetListNewsAdmin: (
    pageSize: number,
    pageNumber: number
  ): Promise<ListRespone<News>> => {
    const url = `/News/GetListNewsAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListNewsById: (IdNews: string): Promise<ListRespone<News>> => {
    const url = `/News/GetListNewsById?IdNews=${IdNews}`;
    return axiosClient.get(url);
  },
  DeleteNews: (IdNews: string): Promise<ListRespone<News>> => {
    const url = `/News/DeleteNews?IdNews=${IdNews}`;
    return axiosClient.delete(url);
  },
  ActiveNews: (IdNews: string, check: boolean): Promise<ListRespone<News>> => {
    const url = `/News/ActiveNews?IdNews=${IdNews}&check=${check}`;
    return axiosClient.put(url);
  },
  UpdateNews: (
    news: PartialRequiredId<News, "id">
  ): Promise<ListRespone<News>> => {
    const url = `/News/UpdateNews`;
    return axiosClient.put(url, news);
  },
  InsertNews: (news: Partial<News>): Promise<ListRespone<News>> => {
    const url = `/News/InsertNews`;
    return axiosClient.post(url, news);
  },
  ActiveNewsByList: (
    listId: string[],
    check: boolean
  ): Promise<ListRespone<News>> => {
    const url = `/News/ActiveNewsByList?check=${check}`;
    return axiosClient.put(url, listId);
  },
  DeleteNewsByList: (listId: string[]): Promise<ListRespone<News>> => {
    const url = `/News/DeleteNewsByList`;
    return axiosClient.delete(url, { data: listId });
  },
  GetFileNews: (IdNews: string) => {
    return `${globalVariable.urlServerApi}/api/News/GetFileNews?fileNameId=${IdNews}`;
  },
  SaveNews: (news: Partial<News>): Promise<ListResponeFormData> => {
    const url = `/News/SaveNews`;
    return axiosClient.post(url, news);
  },
} as const;
