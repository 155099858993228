import { globalVariable } from "../../../globalVariable";
import { ListRespone, ListResponeFormData } from "../../../models/commom";
import { CorporatePartner } from "../../../models/corporatePartner";
import { axiosClient, axiosClientFormData } from "./axiosClient";

export const CorporatePartnerApis = {
  GetListCorporatePartnerAdmin: (
    pageSize: number,
    pageNumber: number
  ): Promise<ListRespone<CorporatePartner>> => {
    const url = `/CorporatePartner/GetListCorporatePartnerAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListCorporatePartnerById: (
    IdCorporatePartner: string
  ): Promise<ListRespone<CorporatePartner>> => {
    const url = `/CorporatePartner/GetListCorporatePartnerById?IdCorporatePartner=${IdCorporatePartner}`;
    return axiosClient.get(url);
  },
  InsertCorporatePartner: (
    data: Partial<CorporatePartner>
  ): Promise<ListResponeFormData> => {
    const url = `/CorporatePartner/InsertCorporatePartner`;
    return axiosClientFormData.post(url, data);
  },
  UpdateCorporatePartner: (
    data: Partial<CorporatePartner>
  ): Promise<ListResponeFormData> => {
    const url = `/CorporatePartner/UpdateCorporatePartner`;
    return axiosClientFormData.put(url, data);
  },
  DeleteCorporatePartnerByList: (
    data: string[]
  ): Promise<ListRespone<CorporatePartner>> => {
    const url = `/CorporatePartner/DeleteCorporatePartnerByList`;
    return axiosClient.post(url, data);
  },
  HideCorporatePartnerByList: (
    data: string[],
    check: boolean
  ): Promise<ListRespone<CorporatePartner>> => {
    const url = `/CorporatePartner/HideCorporatePartnerByList?check=${check}`;
    return axiosClient.post(url, data);
  },
  DeleteCorporatePartner: (
    IdCorporatePartner: string
  ): Promise<ListRespone<CorporatePartner>> => {
    const url = `/CorporatePartner/DeleteCorporatePartner?IdCorporatePartner=${IdCorporatePartner}`;
    return axiosClient.delete(url);
  },
  HideCorporatePartner: (
    IdCorporatePartner: string,
    check: boolean
  ): Promise<ListRespone<CorporatePartner>> => {
    const url = `/CorporatePartner/HideCorporatePartner?IdCorporatePartner=${IdCorporatePartner}&check=${check}`;
    return axiosClient.put(url);
  },
  GetFileCorporatePartner: (fileName: string) => {
    return `${globalVariable.urlServerApi}/api/CorporatePartner/GetFileCorporatePartner?fileNameId=${fileName}`;
  },
} as const;
