import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CategoryServices } from "../../../../models/categoryServices";
import { ListRespone } from "../../../../models/commom";
import { openNotificationWithIcon } from "../../../../units";
import { CategoryServicesApis } from "../../api/categoryServicesApis";

export interface CategoryServicesState {
  loading: boolean;
  apis: ListRespone<CategoryServices>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: CategoryServicesState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as CategoryServices,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCategoryServiceAdmin = createAsyncThunk(
  "CategoryProduct/GetListCategoryServiceAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoryServicesApis.GetListCategoryServiceAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListCategoryServiceClient = createAsyncThunk(
  "CategoryProduct/GetListCategoryServiceClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoryServicesApis.GetListCategoryServiceClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListCategoryServiceById = createAsyncThunk(
  "CategoryProduct/GetListCategoryServiceById",
  async (params: { IdCategoryService: string }, thunkAPI) => {
    try {
      const response = await CategoryServicesApis.GetListCategoryServiceById(params.IdCategoryService);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const InsertCategoryService = createAsyncThunk(
  "CategoryProduct/InsertCategoryService",
  async (params: { categoryServices: CategoryServices }, thunkAPI) => {
    try {
      const response = await CategoryServicesApis.InsertCategoryService(params.categoryServices);
      if (response.success) {
        openNotificationWithIcon("success", "Thêm danh mục lĩnh vực hoạt động thanh công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thêm danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const UpdateCategoryService = createAsyncThunk(
  "CategoryProduct/UpdateCategoryService",
  async (params: { categoryServices: CategoryServices }, thunkAPI) => {
    try {
      const response = await CategoryServicesApis.UpdateCategoryService(params.categoryServices);
      if (response.success) {
        openNotificationWithIcon("success", "Cập nhật danh mục lĩnh vực hoạt động thanh công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Cập nhật danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const HideCategoryServiceByList = createAsyncThunk(
  "CategoryProduct/HideCategoryServiceByList",
  async (params: { listId: string[]; check: boolean }, thunkAPI) => {
    try {
      const response = await CategoryServicesApis.HideCategoryServiceByList(params.listId, params.check);
      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteCategoryServiceByList = createAsyncThunk(
  "CategoryProduct/DeleteCategoryServiceByList",
  async (params: { listId: string[] }, thunkAPI) => {
    try {
      const response = await CategoryServicesApis.DeleteCategoryServiceByList(params.listId);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa danh mục lĩnh vực hoạt động thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa danh mục lĩnh vực hoạt độngthất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const CategoryServicesSlice = createSlice({
  name: "CategoryServices",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCategoryServiceAdmin
    [GetListCategoryServiceAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryServiceAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryServiceAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListCategoryServiceClient
    [GetListCategoryServiceClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryServiceClient.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryServiceClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListCategoryServiceById
    [GetListCategoryServiceById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryServiceById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryServiceById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertCategoryService
    [InsertCategoryService.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertCategoryService.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertCategoryService.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateCategoryService
    [UpdateCategoryService.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateCategoryService.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [UpdateCategoryService.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region HideCategoryServiceByList
    [HideCategoryServiceByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [HideCategoryServiceByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [HideCategoryServiceByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteCategoryServiceByList
    [DeleteCategoryServiceByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteCategoryServiceByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteCategoryServiceByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default CategoryServicesSlice.reducer;
