import { Modal, Typography } from "antd";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
interface ModalContentProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  modalContent:
    | {
        title: string;
        content: string;
      }
    | undefined;
}
const _Modalcontent = ({
  modalContent,
  isModalOpen,
  setIsModalOpen,
}: ModalContentProps) => {
  return (
    <Modal
      title={modalContent?.title}
      visible={isModalOpen}
      footer={null}
      onCancel={() => {
        setIsModalOpen(false);
      }}
    >
      <Typography.Paragraph
        copyable={{
          text: modalContent?.content,
        }}
      >
        {modalContent?.content}
      </Typography.Paragraph>
    </Modal>
  );
};
export const Modalcontent = WithErrorBoundaryCustom(_Modalcontent);
