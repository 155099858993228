import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../../../../models/commom";
import { ContactAndIntroduction } from "../../../../models/contactAndIntroduction";
import { openNotificationWithIcon } from "../../../../units";
import { ContactAndIntroductionApis } from "../../api/contactAndIntroductionApis";

interface ContactAndIntroductionState {
  loading: boolean;
  apis: ListRespone<ContactAndIntroduction>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: ContactAndIntroductionState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as ContactAndIntroduction,
  },
  btnLoading: false,
  postLength: 0,
};

export const GetListContactAndIntroductionAdmin = createAsyncThunk(
  "ContactAndIntroduction/GetListContactAndIntroductionAdmin",
  async (params: { pageSize: number; pageNumber: number; Type?: number }, thunkAPI) => {
    try {
      const response = await ContactAndIntroductionApis.GetListContactAndIntroductionAdmin(
        params.pageSize,
        params.pageNumber,
        params.Type
      );
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách thông tin thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListContactAndIntroductionById = createAsyncThunk(
  "ContactAndIntroduction/GetListContactAndIntroductionById",
  async (params: { IdContactAndIntroduction: string }, thunkAPI) => {
    try {
      const response = await ContactAndIntroductionApis.GetListContactAndIntroductionById(
        params.IdContactAndIntroduction
      );
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy thông tin thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const InsertContactAndIntroduction = createAsyncThunk(
  "ContactAndIntroduction/InsertContactAndIntroduction",
  async (params: { contactAndIntroduction: ContactAndIntroduction }, thunkAPI) => {
    try {
      const response = await ContactAndIntroductionApis.InsertContactAndIntroduction(
        params.contactAndIntroduction
      );
      if (response.data.success) {
        openNotificationWithIcon("success", "Thêm thông tin thành công", response.data.message);
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        openNotificationWithIcon("warning", "Thêm thông tin thất bại", response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const UpdateContactAndIntroduction = createAsyncThunk(
  "ContactAndIntroduction/UpdateContactAndIntroduction",
  async (params: { contactAndIntroduction: ContactAndIntroduction }, thunkAPI) => {
    try {
      const response = await ContactAndIntroductionApis.UpdateContactAndIntroduction(
        params.contactAndIntroduction
      );
      if (response.data.success) {
        openNotificationWithIcon("success", "Cập nhật thông tin thành công", response.data.message);
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        openNotificationWithIcon("warning", "Cập nhật thông tin thất bại", response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteContactAndIntroduction = createAsyncThunk(
  "ContactAndIntroduction/DeleteContactAndIntroduction",
  async (params: { IdContactAndIntroduction: string }, thunkAPI) => {
    try {
      const response = await ContactAndIntroductionApis.DeleteContactAndIntroduction(
        params.IdContactAndIntroduction
      );
      if (response.success) {
        openNotificationWithIcon("success", "Xóa thông tin thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa thông tin thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const ActiveContactAndIntroduction = createAsyncThunk(
  "ContactAndIntroduction/ActiveContactAndIntroduction",
  async (params: { IdContactAndIntroduction: string; isActive: boolean }, thunkAPI) => {
    try {
      const response = await ContactAndIntroductionApis.ActiveContactAndIntroduction(
        params.IdContactAndIntroduction,
        params.isActive
      );
      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteContactAndIntroductionByList = createAsyncThunk(
  "ContactAndIntroduction/DeleteContactAndIntroductionByList",
  async (params: { ListIdContactAndIntroduction: string[] }, thunkAPI) => {
    try {
      const response = await ContactAndIntroductionApis.DeleteContactAndIntroductionByList(
        params.ListIdContactAndIntroduction
      );
      if (response.success) {
        openNotificationWithIcon("success", "Xóa thông tin thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa thông tin thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const ActiveContactAndIntroductionByList = createAsyncThunk(
  "ContactAndIntroduction/ActiveContactAndIntroductionByList",
  async (params: { ListIdContactAndIntroduction: string[]; isActive: boolean }, thunkAPI) => {
    try {
      const response = await ContactAndIntroductionApis.ActiveContactAndIntroductionByList(
        params.ListIdContactAndIntroduction,
        params.isActive
      );
      if (response.success) {
        openNotificationWithIcon("success", "Cập nhật trạng thái thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Cập nhật trạng thái thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const ContactAndIntroductionSlice = createSlice({
  name: "ContactAndIntroduction",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListContactAndIntroductionAdmin
    [GetListContactAndIntroductionAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListContactAndIntroductionAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListContactAndIntroductionAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListContactAndIntroductionById
    [GetListContactAndIntroductionById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListContactAndIntroductionById.fulfilled.type]: (
      state,
      action: PayloadAction<ListRespone<ContactAndIntroduction>>
    ) => {
      state.loading = false;
      state.apis = action.payload;
      state.apis.listPayload = [];
    },
    [GetListContactAndIntroductionById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertContactAndIntroduction
    [InsertContactAndIntroduction.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertContactAndIntroduction.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertContactAndIntroduction.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateContactAndIntroduction
    [UpdateContactAndIntroduction.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateContactAndIntroduction.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [UpdateContactAndIntroduction.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteContactAndIntroduction
    [DeleteContactAndIntroduction.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteContactAndIntroduction.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength - 1;
    },
    [DeleteContactAndIntroduction.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region ActiveContactAndIntroduction
    [ActiveContactAndIntroduction.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [ActiveContactAndIntroduction.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength - 1;
    },
    [ActiveContactAndIntroduction.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteContactAndIntroductionByList
    [DeleteContactAndIntroductionByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteContactAndIntroductionByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength - 1;
    },
    [DeleteContactAndIntroductionByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region ActiveContactAndIntroductionByList
    [ActiveContactAndIntroductionByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [ActiveContactAndIntroductionByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength - 1;
    },
    [ActiveContactAndIntroductionByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default ContactAndIntroductionSlice.reducer;
