import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { deleteCookie, getCookie, openNotificationWithIcon } from "../../../units";

export function PrivateRoutes() {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userAuthSlice);
  const isLoggedIn = getCookie("jwt");
  if (!isLoggedIn) {
    openNotificationWithIcon("info", "Bạn chưa đăng nhập", "Hãy đăng nhập quyền quản trị");
  } else {
    if (user?.success === false && user?.message === "Token hết hạn hoặc đã xảy ra lỗi") {
      openNotificationWithIcon("warning", "Ngăn chặn truy cập", user?.message);
      deleteCookie("jwt");
      return navigate("/", { replace: true });
    } else if (user?.isAdmin === false) {
      openNotificationWithIcon("warning", "Ngăn chặn truy cập", "Bạn không có quyền truy cập vào trang này");
      deleteCookie("jwt");
      return navigate("/Print-authorized-403", { replace: true });
    }
  }

  return !isLoggedIn ? <Navigate to="/" replace /> : <Outlet />;
}
