import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../../../../models/commom";
import { Slide } from "../../../../models/slide";
import { openNotificationWithIcon } from "../../../../units";
import { SlideApis } from "../../api/slideApis";
interface SlideManageSliceState {
  loading: boolean;
  apis: ListRespone<Slide>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: SlideManageSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as Slide,
  },
  btnLoading: false,
  postLength: 0,
};

export const GetListSlideAdmin = createAsyncThunk(
  "slideManage/GetListSlideAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await SlideApis.GetListSlideAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách slide thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListSlideById = createAsyncThunk("slideManage/GetListSlideById", async (params: { IdSlide: string }, thunkAPI) => {
  try {
    const response = await SlideApis.GetListSlideById(params.IdSlide);
    if (response.success) {
      return thunkAPI.fulfillWithValue(response);
    } else {
      openNotificationWithIcon("warning", "Lấy danh sách slide thất bại", response.message);
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    return thunkAPI.rejectWithValue(error);
  }
});
export const InsertSlide = createAsyncThunk("slideManage/InsertSlide", async (params: { slide: Partial<Slide> }, thunkAPI) => {
  try {
    const response = await SlideApis.InsertSlide(params.slide);

    if (response.data.success) {
      openNotificationWithIcon("success", "Thêm slide thành công", response.data.message);
      return thunkAPI.fulfillWithValue(response.data);
    } else {
      openNotificationWithIcon("warning", "Thêm slide thất bại", response.data.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    return thunkAPI.rejectWithValue(error);
  }
});
export const UpdateSlide = createAsyncThunk("slideManage/UpdateSlide", async (params: { slide: Partial<Slide> }, thunkAPI) => {
  try {
    const response = await SlideApis.UpdateSlide(params.slide);
    if (response.data.success) {
      openNotificationWithIcon("success", "Cập nhật slide thành công", response.data.message);
      return thunkAPI.fulfillWithValue(response.data);
    } else {
      openNotificationWithIcon("warning", "Cập nhật slide thất bại", response.data.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    return thunkAPI.rejectWithValue(error);
  }
});
export const HideSlide = createAsyncThunk("slideManage/HideSlide", async (params: { IdSlide: string; check: boolean }, thunkAPI) => {
  try {
    const response = await SlideApis.HideSlide(params.IdSlide, params.check);
    if (response.success) {
      openNotificationWithIcon("success", "Thông báo", response.message);
      return thunkAPI.fulfillWithValue(response);
    } else {
      openNotificationWithIcon("warning", "Thông báo", response.message);
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    return thunkAPI.rejectWithValue(error);
  }
});
export const DeleteSlide = createAsyncThunk("slideManage/DeleteSlide", async (params: { IdSlide: string }, thunkAPI) => {
  try {
    const response = await SlideApis.DeleteSlide(params.IdSlide);
    if (response.success) {
      openNotificationWithIcon("success", "Xóa slide thành công", response.message);
      return thunkAPI.fulfillWithValue(response);
    } else {
      openNotificationWithIcon("warning", "Xóa slide thất bại", response.message);
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    return thunkAPI.rejectWithValue(error);
  }
});

const SlideManageSlice = createSlice({
  name: "SliceManageSlice",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListSlideAdmin
    [GetListSlideAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListSlideAdmin.fulfilled.type]: (state, action: PayloadAction<ListRespone<Slide>>) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListSlideAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListSlideById
    [GetListSlideById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListSlideById.fulfilled.type]: (state, action: PayloadAction<ListRespone<Slide>>) => {
      state.loading = false;
      state.apis = action.payload;
      state.apis.listPayload = [];
    },
    [GetListSlideById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertSlide
    [InsertSlide.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertSlide.fulfilled.type]: (state) => {
      state.btnLoading = false;
    },
    [InsertSlide.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateSlide
    [UpdateSlide.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateSlide.fulfilled.type]: (state) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [UpdateSlide.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region HideSlide
    [HideSlide.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [HideSlide.fulfilled.type]: (state) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [HideSlide.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteSlide
    [DeleteSlide.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteSlide.fulfilled.type]: (state) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteSlide.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default SlideManageSlice.reducer;
