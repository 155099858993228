import { Col, Modal, Row } from "antd";
import DOMPurify from "dompurify";

import WithErrorBoundaryCustom from "../../../../units/errorBounDary/WithErrorBoundaryCustom";
interface ModalViewContentHtmlProps {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  modalContent:
    | {
        title: string;
        content: string;
      }
    | undefined;
}
export const createMarkup = (html: string) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

function _ModalViewContentHtml(props: ModalViewContentHtmlProps) {
  return (
    <div className='ModalViewContentHtml'>
      <Modal
        title={props.modalContent?.title}
        visible={props.isModalOpen}
        footer={null}
        onCancel={() => {
          props.setIsModalOpen(false);
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className='mb-24'>
            <div
              className='preview'
              dangerouslySetInnerHTML={createMarkup(
                props.modalContent?.content || ""
              )}
            ></div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
export const ModalViewContentHtml = WithErrorBoundaryCustom(
  _ModalViewContentHtml
);
