import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CategoryAboutUs } from "../../../../models/categoryAboutUs";
import { ListRespone } from "../../../../models/commom";
import { openNotificationWithIcon } from "../../../../units";
import { CategoryAboutUsApis } from "../../api/categoryAboutUsApis";
import { AboutUsApis } from "../../api/aboutUsApis";

export interface CategoryAboutUsState {
  loading: boolean;
  apis: ListRespone<CategoryAboutUs>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: CategoryAboutUsState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as CategoryAboutUs,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCategoryAboutUsAdmin = createAsyncThunk(
  "CategoryAboutUs/GetListCategoryAboutUsAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoryAboutUsApis.GetListCategoryAboutUsAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách danh mục về chúng tôi thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListCategoryAboutUsClient = createAsyncThunk(
  "CategoryAboutUs/GetListCategoryAboutUsClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoryAboutUsApis.GetListCategoryAboutUsClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách danh mục về chúng tôi thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListCategoryAboutUsById = createAsyncThunk(
  "CategoryAboutUs/GetListCategoryAboutUsById",
  async (params: { IdCategoryAboutUs: string }, thunkAPI) => {
    try {
      const response = await CategoryAboutUsApis.GetListCategoryAboutUsById(params.IdCategoryAboutUs);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách danh mục về chúng tôi thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const InsertCategoryAboutUs = createAsyncThunk(
  "CategoryAboutUs/InsertCategoryAboutUs",
  async (params: { categoryAboutUs: CategoryAboutUs }, thunkAPI) => {
    try {
      const response = await CategoryAboutUsApis.InsertCategoryAboutUs(params.categoryAboutUs);
      if (response.success) {
        openNotificationWithIcon("success", "Thêm danh mục về chúng tôi thanh công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thêm danh mục về chúng tôi thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const UpdateCategoryAboutUs = createAsyncThunk(
  "CategoryAboutUs/UpdateCategoryAboutUs",
  async (params: { categoryAboutUs: CategoryAboutUs }, thunkAPI) => {
    try {
      const response = await CategoryAboutUsApis.UpdateCategoryAboutUs(params.categoryAboutUs);
      if (response.success) {
        openNotificationWithIcon("success", "Cập nhật danh mục về chúng tôi thanh công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Cập nhật danh mục về chúng tôi thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const HideCategoryAboutUsByList = createAsyncThunk(
  "CategoryAboutUs/HideCategoryAboutUsByList",
  async (params: { listId: string[]; check: boolean }, thunkAPI) => {
    try {
      const response = await CategoryAboutUsApis.HideCategoryAboutUsByList(params.listId, params.check);
      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const DeleteCategoryAboutUsByList = createAsyncThunk(
  "CategoryAboutUs/DeleteCategoryAboutUsByList",
  async (params: { listId: string[] }, thunkAPI) => {
    try {
      const response = await CategoryAboutUsApis.DeleteCategoryAboutUsByList(params.listId);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa danh mục về chúng tôi thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa danh mục về chúng tôi thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListCategoryAboutUsForInsertAboutUs = createAsyncThunk(
  "AboutUs/GetListCategoryAboutUsForInsertAboutUs",
  async (params: { pageSize: number, pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoryAboutUsApis.GetListCategoryAboutUsForInsertAboutUs(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách thông tin về chung tôi thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  });

const CategoryAboutUsSlice = createSlice({
  name: "CategoryAboutUs",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCategoryAboutUsAdmin
    [GetListCategoryAboutUsAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryAboutUsAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryAboutUsAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListCategoryAboutUsClient
    [GetListCategoryAboutUsClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryAboutUsClient.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryAboutUsClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListCategoryAboutUsById
    [GetListCategoryAboutUsById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryAboutUsById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryAboutUsById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertCategoryAboutUs
    [InsertCategoryAboutUs.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertCategoryAboutUs.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertCategoryAboutUs.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateCategoryAboutUs
    [UpdateCategoryAboutUs.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateCategoryAboutUs.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [UpdateCategoryAboutUs.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region HideCategoryAboutUsByList
    [HideCategoryAboutUsByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [HideCategoryAboutUsByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [HideCategoryAboutUsByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteCategoryAboutUsByList
    [DeleteCategoryAboutUsByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteCategoryAboutUsByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteCategoryAboutUsByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region GetListCategoryAboutUsForInsertAboutUs
    [GetListCategoryAboutUsForInsertAboutUs.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryAboutUsForInsertAboutUs.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryAboutUsForInsertAboutUs.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default CategoryAboutUsSlice.reducer;
