import React from "react";
import { Modal } from "antd";
import WithErrorBoundaryCustom from "../../../../units/errorBounDary/WithErrorBoundaryCustom";


interface IProps {
  children: React.ReactNode;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  title: string;
  width?: string;
  top?: number;
}

function _Modal(props: IProps) {
  const { visible, setVisible, children, title, width, top } = props;

  return (
    <div className="Modal">
      <Modal
        title={title}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={false}
        width={width || "85%"}
        style={{ top: top || 20 }}
      >
        {children}
      </Modal>
    </div>
  );
}

export const ModalContent = WithErrorBoundaryCustom(_Modal);
