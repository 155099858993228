import { globalVariable } from "../../../globalVariable";
import { ListRespone } from "../../../models/commom";
import { InforServices } from "../../../models/inforServices";

import { axiosClient } from "./axiosClient";

export const InforServicesApis = {
  GetListInforServiceAdmin: (pageSize: number, pageNumber: number): Promise<ListRespone<InforServices>> => {
    const url = `/InforService/GetListInforServiceAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListInforServiceClient: (pageSize: number, pageNumber: number): Promise<ListRespone<InforServices>> => {
    const url = `/InforService/GetListInforServiceClient?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListInforServiceById: (IdInforService: string): Promise<ListRespone<InforServices>> => {
    const url = `/InforService/GetListInforServiceById?IdInforService=${IdInforService}`;
    return axiosClient.get(url);
  },
  InsertInforService: (inforService: Partial<InforServices>): Promise<ListRespone<InforServices>> => {
    const url = `/InforService/InsertInforService`;
    return axiosClient.post(url, inforService);
  },
  UpdateInforService: (inforService: Partial<InforServices>): Promise<ListRespone<InforServices>> => {
    const url = `/InforService/UpdateInforService`;
    return axiosClient.put(url, inforService);
  },
  DeleteInforServiceByList: (listId: string[]): Promise<ListRespone<InforServices>> => {
    const url = `/InforService/DeleteInforServiceByList`;
    return axiosClient.delete(url, { data: listId });
  },
  ActiveInforServiceByList: (listId: string[], check: boolean): Promise<ListRespone<InforServices>> => {
    const url = `/InforService/ActiveInforServiceByList?check=${check}`;
    return axiosClient.put(url, listId);
  },
  GetFileInforService: (IdInforService: string) => {
    return `${globalVariable.urlServerApi}/api/InforService/GetFileInforService?fileNameId=${IdInforService}`;
  },
  SaveInforService: (file: File) => {
    const url = `/InforService/SaveInforService`;
    return axiosClient.post(url, file);
  },
} as const;
