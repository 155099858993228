import { CategoriesNew } from "../../../models/categoriesNew";
import { ListRespone } from "../../../models/commom";
import { axiosClient } from "./axiosClient";

export const CategoriesNewApis = {
  GetListCategoriesNewsAdmin: (
    pageSize: number,
    pageNumber: number
  ): Promise<ListRespone<CategoriesNew>> => {
    const url = `/CategoryNew/GetListCategoriesNewsAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListCategoriesNewsClient: (
    pageSize: number,
    pageNumber: number
  ): Promise<ListRespone<CategoriesNew>> => {
    const url = `/CategoryNew/GetListCategoriesNewsClient?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListCategoriesNewsById: (
    IdCategoriesNews: string
  ): Promise<ListRespone<CategoriesNew>> => {
    const url = `/CategoryNew/GetListCategoriesNewsById?IdCategoriesNews=${IdCategoriesNews}`;
    return axiosClient.get(url);
  },
  InsertCategoriesNews: (
    data: Partial<CategoriesNew>
  ): Promise<ListRespone<CategoriesNew>> => {
    const url = `/CategoryNew/InsertCategoriesNews`;
    return axiosClient.post(url, data);
  },
  UpdateCategoriesNews: (
    data: Partial<CategoriesNew>
  ): Promise<ListRespone<CategoriesNew>> => {
    const url = `/CategoryNew/UpdateCategoriesNews`;
    return axiosClient.put(url, data);
  },
  DeleteCategoriesNewsByList: (
    listId: string[]
  ): Promise<ListRespone<CategoriesNew>> => {
    const url = `/CategoryNew/DeleteCategoriesNewsByList`;
    return axiosClient.delete(url, { data: listId });
  },
  HideCategoriesNewsByList: (
    listId: string[],
    check: boolean
  ): Promise<ListRespone<CategoriesNew>> => {
    const url = `/CategoryNew/HideCategoriesNewsByList?check=${check}`;
    return axiosClient.put(url, listId);
  },
} as const;
