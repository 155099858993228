import { ListRespone } from "../../../models/commom";
import { User } from "../../../models/user";
import { UserLoginRequest, UserloginRespone } from "../../../models/userAuth";
import { axiosClient } from "./axiosClient";

export const AuthApis = {
  Login(user: Partial<UserLoginRequest>): Promise<UserloginRespone> {
    const url = `/User/Login`;
    return axiosClient.post(url, user);
  },
  Register(user: Partial<User>): Promise<ListRespone<User>> {
    const url = `/User/Register`;
    return axiosClient.post(url, user);
  },
} as const;
