// create store redux toolkit
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import AboutUsSlice from "../package/admin/features/aboutUs/AboutUsSlice";
import CareerSlice from "../package/admin/features/career/CareerSlice";
import CategoryAboutUsSlice from "../package/admin/features/categoryAboutUs/CategoryAboutUsSlice";
import CategoryNewSlice from "../package/admin/features/categoryNew/CategoryNewSlice";
import CategoryServicesSlice from "../package/admin/features/categoryService/CategoryServicesSlice";
import ContactSlice from "../package/admin/features/contact/ContactSlice";
import ContactAndIntroductionSlice from "../package/admin/features/contactAndIntroduction/ContactAndIntroductionSlice";
import CorporatePartnerSlice from "../package/admin/features/corporatePartner/CorporatePartnerSlice";
import InforServicesSlice from "../package/admin/features/inforServices/InforServicesSlice";
import LeaderShipSlice from "../package/admin/features/leaderShip/LeaderShipSlice";
import NewsSlice from "../package/admin/features/news/NewsSlice";
import SlideManageSlice from "../package/admin/features/slideManage/SlideManageSlice";
import { userManageSlice } from "../package/admin/features/userManage";
import rootSaga from "./rootSaga";
import userAuthSlice from "./userAuthSlice";
import CategoryProductSlice from "../package/admin/features/categoryProduct/CategoryProductSlice";
import InforProductSlice from "../package/admin/features/inforProduct/InforProductSlice";
import MenuSlice from "../package/admin/features/Menu/MenuSlice";

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
const arrMiddleware: any[] = [sagaMiddleware];
if (process.env.NODE_ENV === "development") {
  arrMiddleware.push(logger);
}

export const store = configureStore({
  reducer: {
    SlideManage: SlideManageSlice,
    userAuthSlice: userAuthSlice.reducer,
    userManage: userManageSlice.reducer,
    LeaderShip: LeaderShipSlice,
    CorporatePartner: CorporatePartnerSlice,
    ContactAndIntroduction: ContactAndIntroductionSlice,
    CategoryNew: CategoryNewSlice,
    News: NewsSlice,
    Contact: ContactSlice,
    Career: CareerSlice,
    CategoryService: CategoryServicesSlice,
    InforServices: InforServicesSlice,
    CategoryAboutUs: CategoryAboutUsSlice,
    AboutUs: AboutUsSlice,
    CategoryProduct: CategoryProductSlice,
    InforProduct: InforProductSlice,
    MenuSlice: MenuSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...arrMiddleware),
});
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
