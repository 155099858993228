import {
  BarsOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
} from "antd";
import { ColumnsType, FilterValue } from "antd/lib/table/interface";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { CategoryProduct as CategoryProductionsModel } from "../../../../../models/categoryProduct";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { Modalcontent } from "../../userManage/components/Modalcontent";
import {
  DeleteCategoryProductionByList,
  GetListCategoryProductionAdmin,
  HideCategoryProductionByList,
} from "../CategoryProductSlice";

function _CategoryProduction() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { loading, apis, btnLoading, postLength } = useAppSelector((state) => state.CategoryProduct);
  const [isModalOpenDes, setIsModalOpenDes] = useState(false);
  const [modalContentDes, setModalContentDes] = useState<{
    title: string;
    content: string;
  }>();
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  useEffect(() => {
    dispatch(
      GetListCategoryProductionAdmin({
        pageSize: 0,
        pageNumber: 0,
      }),
    );
    setSelectedRowKeys([]);
  }, [dispatch, postLength]);

  const handleChange: TableProps<CategoryProductionsModel>["onChange"] = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const menuAction = (record: CategoryProductionsModel) => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          icon={<EditOutlined />}
          onClick={() => {
            navigate(`/admin/categoryProduct/edit/${record.id}`);
          }}
        >
          Chỉnh sửa
        </Menu.Item>
      </Menu>
    );
  };

  const columns: ColumnsType<CategoryProductionsModel> = [
    {
      title: "Tiêu đề",
      dataIndex: "nameProduct",
      key: "nameProduct",
      filters: apis.listPayload?.map((item) => ({
        text: item.nameProduct,
        value: item.nameProduct,
      })),
      filteredValue: filteredInfo?.nameProduction || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.nameProduct.startsWith(value),
    },
    {
      title: "Mã danh mục",
      dataIndex: "codeProduct",
      key: "codeProduct",
      filters: apis.listPayload?.map((item) => ({
        text: item.codeProduct,
        value: item.codeProduct,
      })),
      filteredValue: filteredInfo?.codeProduction || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.codeProduct.startsWith(value),
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => {
            setModalContentDes({
              title: `Mô tả - ${record.nameProduct}`,
              content: text,
            });
            setIsModalOpenDes(true);
          }}
        >
          Xem
        </Button>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "isHide",
      key: "isHide",
      filters: apis.listPayload?.map((item) => ({
        text: item.isHide,
        value: item.isHide,
      })),
      filteredValue: filteredInfo?.isHide || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.isHide === value,
      render: (isHide) => (!isHide ? <Tag color="blue">Đã kích hoạt</Tag> : <Tag color="warning">Chưa kich hoạt</Tag>),
    },
    {
      title: "Ngày tạo",
      dataIndex: "createDate",
      key: "createDate",
      filters: apis.listPayload?.map((item) => ({
        text: moment(item.createDate).format("DD-MM-YYYY HH:mm:ss"),
        value: moment(item.createDate).format("DD-MM-YYYY HH:mm:ss"),
      })),
      filteredValue: filteredInfo?.createDate || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.createDate.toString().startsWith(value),
      render: (text) => moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Thao tác",
      dataIndex: "Action",
      key: "Action",
      fixed: "right",
      render: (_, record) => (
        <Dropdown overlay={menuAction(record)} trigger={["click"]}>
          <BarsOutlined style={{ fontSize: 24 }} />
        </Dropdown>
      ),
      width: 10,
    },
  ];

  return (
    <div className="CategoryProductions">
      <Modalcontent isModalOpen={isModalOpenDes} setIsModalOpen={setIsModalOpenDes} modalContent={modalContentDes} />
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <Typography.Title level={3}>Danh mục sản phẩm </Typography.Title>
            <Divider />
            <Space
              style={{
                marginBottom: 16,
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  setSelectedRowKeys([]);
                }}
                disabled={!(selectedRowKeys.length > 0)}
                loading={loading}
              >
                Bỏ chọn {selectedRowKeys.length} mục
              </Button>

              <Button
                onClick={(e) => navigate("/admin/categoryProduct/new")}
                size="small"
                type="default"
                icon={[
                  <svg
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1621"
                    width="12"
                    height="12"
                  >
                    <path
                      d="M556.8 917.333333H256c-12.8 0-21.333333-8.533333-21.333333-21.333333V256c0-12.8 8.533333-21.333333 21.333333-21.333333h640c12.8 0 21.333333 8.533333 21.333333 21.333333v298.666667c0 12.8-8.533333 21.333333-21.333333 21.333333s-21.333333-8.533333-21.333333-21.333333V277.333333H277.333333v597.333334h279.466667c12.8 0 21.333333 8.533333 21.333333 21.333333s-10.666667 21.333333-21.333333 21.333333z"
                      p-id="1622"
                    ></path>
                    <path
                      d="M236.8 832H128c-12.8 0-21.333333-8.533333-21.333333-21.333333V128c0-12.8 8.533333-21.333333 21.333333-21.333333h682.666667c12.8 0 21.333333 8.533333 21.333333 21.333333v128c0 12.8-8.533333 21.333333-21.333333 21.333333s-21.333333-8.533333-21.333334-21.333333V149.333333H149.333333v640h87.466667c12.8 0 21.333333 8.533333 21.333333 21.333334s-10.666667 21.333333-21.333333 21.333333zM896 789.333333H640c-12.8 0-21.333333-8.533333-21.333333-21.333333s8.533333-21.333333 21.333333-21.333333h256c12.8 0 21.333333 8.533333 21.333333 21.333333s-8.533333 21.333333-21.333333 21.333333z"
                      p-id="1623"
                    ></path>
                    <path
                      d="M768 917.333333c-12.8 0-21.333333-8.533333-21.333333-21.333333V640c0-12.8 8.533333-21.333333 21.333333-21.333333s21.333333 8.533333 21.333333 21.333333v256c0 12.8-8.533333 21.333333-21.333333 21.333333zM512 533.333333h-128c-12.8 0-21.333333-8.533333-21.333333-21.333333v-128c0-12.8 8.533333-21.333333 21.333333-21.333333h128c12.8 0 21.333333 8.533333 21.333333 21.333333v128c0 12.8-8.533333 21.333333-21.333333 21.333333z m-106.666667-42.666666h85.333334v-85.333334h-85.333334v85.333334zM768 533.333333h-128c-12.8 0-21.333333-8.533333-21.333333-21.333333v-128c0-12.8 8.533333-21.333333 21.333333-21.333333h128c12.8 0 21.333333 8.533333 21.333333 21.333333v128c0 12.8-8.533333 21.333333-21.333333 21.333333z m-106.666667-42.666666h85.333334v-85.333334h-85.333334v85.333334zM512 789.333333h-128c-12.8 0-21.333333-8.533333-21.333333-21.333333v-128c0-12.8 8.533333-21.333333 21.333333-21.333333h128c12.8 0 21.333333 8.533333 21.333333 21.333333v128c0 12.8-8.533333 21.333333-21.333333 21.333333z m-106.666667-42.666666h85.333334v-85.333334h-85.333334v85.333334z"
                      p-id="1624"
                    ></path>
                  </svg>,
                ]}
              >
                Thêm mới danh mục
              </Button>
              <Popconfirm
                title="Bạn có chắc chắn không ?"
                okText="Có"
                disabled={!(selectedRowKeys.length > 0)}
                cancelText="Không"
                onConfirm={() =>
                  dispatch(
                    HideCategoryProductionByList({
                      listId: selectedRowKeys as string[],
                      check: false,
                    }),
                  )
                }
              >
                <Button
                  type="primary"
                  disabled={!(selectedRowKeys.length > 0)}
                  ghost
                  loading={btnLoading}
                  icon={<CheckCircleOutlined />}
                >
                  Kích hoạt {selectedRowKeys.length} mục
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Bạn có chắc chắn không ?"
                okText="Có"
                disabled={!(selectedRowKeys.length > 0)}
                cancelText="Không"
                onConfirm={() =>
                  dispatch(
                    HideCategoryProductionByList({
                      listId: selectedRowKeys as string[],
                      check: true,
                    }),
                  )
                }
              >
                <Button danger type="default" disabled={!(selectedRowKeys.length > 0)} loading={btnLoading}
                        icon={<StopOutlined />}>
                  Khóa {selectedRowKeys.length} mục
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Bạn có chắc chắn không ?"
                okText="Có"
                cancelText="Không"
                disabled={!(selectedRowKeys.length > 0)}
                onConfirm={() => {
                  dispatch(
                    DeleteCategoryProductionByList({
                      listId: selectedRowKeys as string[],
                    }),
                  );
                  setSelectedRowKeys([]);
                }}
              >
                <Button danger type="primary" loading={btnLoading} disabled={!(selectedRowKeys.length > 0)}
                        icon={<DeleteOutlined />}>
                  Xóa {selectedRowKeys.length} mục
                </Button>
              </Popconfirm>
            </Space>
            <Table
              scroll={{
                x: "auto",
              }}
              rowKey={(record) => record.id}
              columns={columns}
              rowSelection={rowSelection}
              dataSource={apis.listPayload}
              onChange={handleChange}
              loading={loading}
              pagination={{
                total: apis.totalElament,
                showSizeChanger: true,
                showLessItems: true,
                pageSizeOptions: ["6", "10", "20", "50", "100"],
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export const CategoryProduct = WithErrorBoundaryCustom(_CategoryProduction);
