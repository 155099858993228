import { globalVariable } from "../../../globalVariable";
import { aboutUs } from "../../../models/aboutUs";
import { ListRespone } from "../../../models/commom";
import { axiosClient } from "./axiosClient";

export const AboutUsApis = {
  GetListAboutUsAdmin: (page: number, pageSize: number): Promise<ListRespone<aboutUs>> => {
    const url = `/AboutUs/GetListAboutUsAdmin?page=${page}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListAboutUsById: (IdAboutUs: string): Promise<ListRespone<aboutUs>> => {
    const url = `/AboutUs/GetListAboutUsById?IdAboutUs=${IdAboutUs}`;
    return axiosClient.get(url);
  },
  InsertAboutUs: (aboutUs: Partial<aboutUs>): Promise<ListRespone<aboutUs>> => {
    const url = `/AboutUs/InsertAboutUs`;
    return axiosClient.post(url, aboutUs);
  },
  UpdateAboutUs: (aboutUs: Partial<aboutUs>): Promise<ListRespone<aboutUs>> => {
    const url = `/AboutUs/UpdateAboutUs`;
    return axiosClient.put(url, aboutUs);
  },
  DeleteAboutUsByList: (listId: string[]): Promise<ListRespone<aboutUs>> => {
    const url = `/AboutUs/DeleteAboutUsByList`;
    return axiosClient.delete(url, { data: listId });
  },
  ActiveAboutUsByList: (listId: string[], check: boolean): Promise<ListRespone<aboutUs>> => {
    const url = `/AboutUs/ActiveAboutUsByList?check=${check}`;
    return axiosClient.put(url, listId);
  },
  SaveAboutUs: (file: File) => {
    const url = `/AboutUs/SaveAboutUs`;
    return axiosClient.post(url, file);
  },
  GetFileAboutUs: (IdAboutUs: string) => {
    return `${globalVariable.urlServerApi}/api/AboutUs/GetFileAboutUs?fileNameId=${IdAboutUs}`;
  },
} as const;
