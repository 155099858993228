import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { aboutUs } from "../../../../models/aboutUs";
import { ListRespone } from "../../../../models/commom";

import { openNotificationWithIcon } from "../../../../units";
import { AboutUsApis } from "../../api/aboutUsApis";

interface AboutUsSliceState {
  loading: boolean;
  apis: ListRespone<aboutUs>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: AboutUsSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as aboutUs,
  },
  btnLoading: false,
  postLength: 0,
};

export const GetListAboutUsAdmin = createAsyncThunk(
  "AboutUs/GetListAboutUsAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await AboutUsApis.GetListAboutUsAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách thông tin về chung tôi thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
// export const GetListAboutUsClient = createAsyncThunk(
//   "AboutUs/GetListAboutUsClient",
//   async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
//     try {
//       const response = await AboutUsApis.GetListAboutUsClient(params.pageSize, params.pageNumber);
//       if (response.success) {
//         return thunkAPI.fulfillWithValue(response);
//       } else {
//         openNotificationWithIcon("warning", "Lấy danh sách thông tin về chung tôi thất bại", response.message);
//         return thunkAPI.rejectWithValue(response);
//       }
//     } catch (error: any) {
//       openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );
export const GetListAboutUsById = createAsyncThunk("AboutUs/GetListAboutUsById", async (params: { IdAboutUs: string }, thunkAPI) => {
  try {
    const response = await AboutUsApis.GetListAboutUsById(params.IdAboutUs);
    if (response.success) {
      return thunkAPI.fulfillWithValue(response);
    } else {
      openNotificationWithIcon("warning", "Lấy thông tin về chung tôi thất bại", response.message);
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    return thunkAPI.rejectWithValue(error);
  }
});
export const InsertAboutUs = createAsyncThunk("AboutUs/InsertAboutUs", async (params: { AboutUs: Partial<aboutUs> }, thunkAPI) => {
  try {
    const response = await AboutUsApis.InsertAboutUs(params.AboutUs);
    if (response.success) {
      openNotificationWithIcon("success", "Thêm thông tin về chung tôi thành công", response.message);
      return thunkAPI.fulfillWithValue(response);
    } else {
      openNotificationWithIcon("warning", "Thêm thông tin về chung tôi thất bại", response.message);
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    return thunkAPI.rejectWithValue(error);
  }
});
export const UpdateAboutUs = createAsyncThunk("AboutUs/UpdateAboutUs", async (params: { AboutUs: Partial<aboutUs> }, thunkAPI) => {
  try {
    const response = await AboutUsApis.UpdateAboutUs(params.AboutUs);
    if (response.success) {
      openNotificationWithIcon("success", "Cập nhật thông tin về chung tôi thành công", response.message);
      return thunkAPI.fulfillWithValue(response);
    } else {
      openNotificationWithIcon("warning", "Cập nhật thông tin về chung tôi thất bại", response.message);
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    return thunkAPI.rejectWithValue(error);
  }
});
export const DeleteAboutUsByList = createAsyncThunk("AboutUs/DeleteAboutUsByList", async (params: { listId: string[] }, thunkAPI) => {
  try {
    const response = await AboutUsApis.DeleteAboutUsByList(params.listId);
    if (response.success) {
      openNotificationWithIcon("success", "Xóa thông tin về chung tôi thành công", response.message);
      return thunkAPI.fulfillWithValue(response);
    } else {
      openNotificationWithIcon("warning", "Xóa thông tin về chung tôi thất bại", response.message);
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    return thunkAPI.rejectWithValue(error);
  }
});
export const ActiveAboutUsByList = createAsyncThunk(
  "AboutUs/ActiveAboutUsByList",
  async (params: { listId: string[]; check: boolean }, thunkAPI) => {
    try {
      const response = await AboutUsApis.ActiveAboutUsByList(params.listId, params.check);
      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const SaveAboutUs = createAsyncThunk("AboutUs/SaveAboutUs", async (params: { AboutUs: File }, thunkAPI) => {
  try {
    const response = await AboutUsApis.SaveAboutUs(params.AboutUs);
    if (response.data.success) {
      openNotificationWithIcon("success", "Lưu thông tin về chung tôi thành công", response.data.message);
      return thunkAPI.fulfillWithValue(response.data);
    } else {
      openNotificationWithIcon("warning", "Lưu thông tin về chung tôi thất bại", response.data.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    return thunkAPI.rejectWithValue(error);
  }
});

const AboutUsSlice = createSlice({
  name: "AboutUs",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListAboutUsAdmin
    [GetListAboutUsAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListAboutUsAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListAboutUsAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    // //#region GetListAboutUsClient
    // [GetListAboutUsClient.pending.type]: (state) => {
    //   state.loading = true;
    // },
    // [GetListAboutUsClient.fulfilled.type]: (state, action) => {
    //   state.loading = false;
    //   state.apis = action.payload;
    // },
    // [GetListAboutUsClient.rejected.type]: (state) => {
    //   state.loading = false;
    // },
    // //#endregion
    //#region GetListAboutUsById
    [GetListAboutUsById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListAboutUsById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
      state.apis.listPayload = [];
    },
    [GetListAboutUsById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertAboutUs
    [InsertAboutUs.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertAboutUs.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertAboutUs.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateAboutUs
    [UpdateAboutUs.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateAboutUs.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [UpdateAboutUs.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteAboutUsByList
    [DeleteAboutUsByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteAboutUsByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteAboutUsByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region ActiveAboutUsByList
    [ActiveAboutUsByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [ActiveAboutUsByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [ActiveAboutUsByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion

  },
});

export default AboutUsSlice.reducer;
