import { globalVariable } from "../../../globalVariable";
import { ListRespone } from "../../../models/commom";
import { InforProduct } from "../../../models/inforProduct";

import { axiosClient } from "./axiosClient";

export const InforProductApis = {
  GetListInforProductionAdmin: (pageSize: number, pageNumber: number): Promise<ListRespone<InforProduct>> => {
    const url = `/InforProduction/GetListInforProductionAdmin?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListInforProductionClient: (pageSize: number, pageNumber: number): Promise<ListRespone<InforProduct>> => {
    const url = `/InforProduction/GetListInforProductionClient?pageSize=${pageSize}&pageNumber=${pageNumber}`;
    return axiosClient.get(url);
  },
  GetListInforProductionById: (IdInforProduction: string): Promise<ListRespone<InforProduct>> => {
    const url = `/InforProduction/GetListInforProductionById?IdInforProduction=${IdInforProduction}`;
    return axiosClient.get(url);
  },
  InsertInforProduction: (inforProduction: Partial<InforProduct>): Promise<ListRespone<InforProduct>> => {
    const url = `/InforProduction/InsertInforProduction`;
    return axiosClient.post(url, inforProduction);
  },
  UpdateInforProduction: (inforProduction: Partial<InforProduct>): Promise<ListRespone<InforProduct>> => {
    const url = `/InforProduction/UpdateInforProduction`;
    return axiosClient.put(url, inforProduction);
  },
  DeleteInforProductionByList: (listId: string[]): Promise<ListRespone<InforProduct>> => {
    const url = `/InforProduction/DeleteInforProductionByList`;
    return axiosClient.delete(url, { data: listId });
  },
  ActiveInforProductionByList: (listId: string[], check: boolean): Promise<ListRespone<InforProduct>> => {
    const url = `/InforProduction/ActiveInforProductionByList?check=${check}`;
    return axiosClient.put(url, listId);
  },
  GetFileInforProduction: (IdInforProduction: string) => {
    return `${globalVariable.urlServerApi}/api/InforProduction/GetFileInforProduction?fileNameId=${IdInforProduction}`;
  },
  SaveInforProduction: (file: File) => {
    const url = `/InforProduction/SaveInforProduction`;
    return axiosClient.post(url, file);
  },
} as const;
