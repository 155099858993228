import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { InforProduct } from "../../../../../models/inforProduct";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { BraftRichEditor } from "../../../components/BraftEditor";
import { createMarkup } from "../../../components/modalViewContentHtml";
import { GetListCategoryProductionClient } from "../../categoryProduct";
import { GetListInforProductionById, UpdateInforProduction } from "../InforProductSlice";

function _EditInforProduct() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, btnLoading, apis } = useAppSelector((state) => state.InforProduct);
  const {
    loading: loadingCategoryProduct,
    apis: apisCategoryProduct,
  } = useAppSelector((state) => state.CategoryProduct);
  const [preview, setPreview] = useState("");
  const [initHtmlContent, setInitHtmlContent] = useState("");
  const convertedContentRef = React.useRef("");
  const fromref = React.createRef<FormInstance>();
  useEffect(() => {
    if (params.id) {
      dispatch(GetListInforProductionById({ IdInforProduction: params.id as string })).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          setInitHtmlContent(res?.payload.payload.contentProduct);
        }
      });
    }
  }, [dispatch, params.id]);
  useEffect(() => {
    dispatch(GetListCategoryProductionClient({ pageNumber: 0, pageSize: 0 }));
  }, [dispatch]);
  const GetHtmlContent = (html: string) => {
    convertedContentRef.current = html;
  };
  useEffect(() => {
    fromref.current?.setFieldsValue(apis.payload);
  }, [apis.payload]);

  const onFinish = (values: InforProduct) => {
    values.id = apis.payload.id || (params.id as string);
    values.contentProduct = convertedContentRef.current;
    dispatch(UpdateInforProduction({ inforProduction: values })).then((res: any) => {
      if (res.meta.requestStatus === "fulfilled") {
        // navigate("/admin/inforProduct");
      }
    });
  };
  return (
    <div className="EditInforProduct Rich-editor">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <Spin spinning={loading} size="large">
            <Card bordered={false} className="criclebox h-full">
              <Typography.Title level={5}>Chỉnh sửa sản phẩm</Typography.Title>
              <Form
                onFinish={onFinish}
                layout={"vertical"}
                className="ant-advanced-search-form"
                name="editInforProduct"
                ref={fromref}
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item label="Tiêu đề" name="titleProduct">
                      <Input.TextArea placeholder="Tiêu đề" showCount />
                    </Form.Item>
                    <Form.Item label="Mô tả" name="description">
                      <Input.TextArea placeholder="Mô tả" showCount />
                    </Form.Item>
                    <Form.Item>
                      <Space direction="horizontal" wrap>
                        <Button type="primary" htmlType="submit" loading={btnLoading}>
                          Xác nhận
                        </Button>
                        <Button htmlType="button" loading={btnLoading} onClick={() => navigate(-1)}>
                          Quay lại
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Phân loại theo danh mục"
                      name="idCategoryProduct"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng phân loại nội dung",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        loading={loadingCategoryProduct}
                        optionFilterProp="children"

                      >
                        {apisCategoryProduct.listPayload?.map((item) => (
                          <Select.Option value={item.id}>
                            {item.nameProduct} - {item.codeProduct}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Spin>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <Skeleton loading={loading} active avatar paragraph={{ rows: 10 }}>
            <Card bordered={false} className="criclebox h-full">
              <header className="App-header">Viết nội dung bài viết</header>
              <Space direction="vertical" size={"large"}>
                <Card bordered={true} className="criclebox h-full">
                  <BraftRichEditor language="en" setHtmlContent={GetHtmlContent} initHtmlContent={initHtmlContent} />
                </Card>
                <Button onClick={() => setPreview(convertedContentRef.current)} type="primary">
                  Xem trước nội dung
                </Button>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <Skeleton loading={loading} active avatar paragraph={{ rows: 15 }}>
            <Badge.Ribbon color={"blue"} text="Xem trước">
              <Card bordered={false} className="criclebox h-full">
                <div className="preview" dangerouslySetInnerHTML={createMarkup(preview || "")}></div>
              </Card>
            </Badge.Ribbon>
          </Skeleton>
        </Col>
      </Row>
    </div>
  );
}

export const EditInforProduct = WithErrorBoundaryCustom(_EditInforProduct);
