import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListRespone } from "../../../../models/commom";
import { InforProduct } from "../../../../models/inforProduct";
import { openNotificationWithIcon } from "../../../../units";
import { InforProductApis } from "../../api/InforProductApis";

interface InforProductionSliceState {
  loading: boolean;
  apis: ListRespone<InforProduct>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: InforProductionSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as InforProduct,
  },
  btnLoading: false,
  postLength: 0,
};

export const GetListInforProductionAdmin = createAsyncThunk(
  "InforProduction/GetListInforProductionAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await InforProductApis.GetListInforProductionAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách thông tin sản phẩm thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListInforProductionClient = createAsyncThunk(
  "InforProduction/GetListInforProductionClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await InforProductApis.GetListInforProductionClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách thông tin sản phẩm thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListInforProductionById = createAsyncThunk(
  "InforProduction/GetListInforProductionById",
  async (params: { IdInforProduction: string }, thunkAPI) => {
    try {
      const response = await InforProductApis.GetListInforProductionById(params.IdInforProduction);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy thông tin sản phẩm thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const InsertInforProduction = createAsyncThunk(
  "InforProduction/InsertInforProduction",
  async (params: { inforProduction: Partial<InforProduct> }, thunkAPI) => {
    try {
      const response = await InforProductApis.InsertInforProduction(params.inforProduction);
      if (response.success) {
        openNotificationWithIcon("success", "Thêm thông tin sản phẩm thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thêm thông tin sản phẩm thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const UpdateInforProduction = createAsyncThunk(
  "InforProduction/UpdateInforProduction",
  async (params: { inforProduction: Partial<InforProduct> }, thunkAPI) => {
    try {
      const response = await InforProductApis.UpdateInforProduction(params.inforProduction);
      if (response.success) {
        openNotificationWithIcon("success", "Cập nhật thông tin sản phẩm thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Cập nhật thông tin sản phẩm thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const DeleteInforProductionByList = createAsyncThunk(
  "InforProduction/DeleteInforProductionByList",
  async (params: { listId: string[] }, thunkAPI) => {
    try {
      const response = await InforProductApis.DeleteInforProductionByList(params.listId);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa thông tin sản phẩm thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa thông tin sản phẩm thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const ActiveInforProductionByList = createAsyncThunk(
  "InforProduction/ActiveInforProductionByList",
  async (params: { listId: string[]; check: boolean }, thunkAPI) => {
    try {
      const response = await InforProductApis.ActiveInforProductionByList(params.listId, params.check);
      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const SaveInforProduction = createAsyncThunk(
  "InforProduction/SaveInforProduction",
  async (params: { inforProduction: File }, thunkAPI) => {
    try {
      const response = await InforProductApis.SaveInforProduction(params.inforProduction);
      if (response.data.success) {
        openNotificationWithIcon("success", "Lưu thông tin sản phẩm thành công", response.data.message);
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        openNotificationWithIcon("warning", "Lưu thông tin sản phẩm thất bại", response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const InforProductSlice = createSlice({
  name: "InforProduction",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListInforProductionAdmin
    [GetListInforProductionAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforProductionAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListInforProductionAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListInforProductionClient
    [GetListInforProductionClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforProductionClient.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListInforProductionClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListInforProductionById
    [GetListInforProductionById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforProductionById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
      state.apis.listPayload = [];
    },
    [GetListInforProductionById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertInforProduction
    [InsertInforProduction.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertInforProduction.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertInforProduction.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateInforProduction
    [UpdateInforProduction.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateInforProduction.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [UpdateInforProduction.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteInforProductionByList
    [DeleteInforProductionByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteInforProductionByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteInforProductionByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region ActiveInforProductionByList
    [ActiveInforProductionByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [ActiveInforProductionByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [ActiveInforProductionByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default InforProductSlice.reducer;
