import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CategoriesNew } from "../../../../models/categoriesNew";
import { ListRespone } from "../../../../models/commom";
import { openNotificationWithIcon } from "../../../../units";
import { CategoriesNewApis } from "../../api/categoriesNewApis";

interface CategoryNewState {
  loading: boolean;
  apis: ListRespone<CategoriesNew>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: CategoryNewState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as CategoriesNew,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCategoriesNewsAdmin = createAsyncThunk(
  "categoryNewManage/GetListCategoriesNewsAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoriesNewApis.GetListCategoriesNewsAdmin(
        params.pageSize,
        params.pageNumber
      );
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon(
          "warning",
          "Lấy danh sách danh mục tin tức thất bại",
          response.message
        );
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListCategoriesNewsClient = createAsyncThunk(
  "categoryNewManage/GetListCategoriesNewsClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoriesNewApis.GetListCategoriesNewsClient(
        params.pageSize,
        params.pageNumber
      );
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon(
          "warning",
          "Lấy danh sách danh mục tin tức thất bại",
          response.message
        );
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListCategoriesNewsById = createAsyncThunk(
  "categoryNewManage/GetListCategoriesNewsById",
  async (params: { IdCategoriesNews: string }, thunkAPI) => {
    try {
      const response = await CategoriesNewApis.GetListCategoriesNewsById(params.IdCategoriesNews);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon(
          "warning",
          "Lấy danh sách danh mục tin tức thất bại",
          response.message
        );
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const InsertCategoriesNews = createAsyncThunk(
  "categoryNewManage/InsertCategoriesNews",
  async (params: { categoriesNew: CategoriesNew }, thunkAPI) => {
    try {
      const response = await CategoriesNewApis.InsertCategoriesNews(params.categoriesNew);
      if (response.success) {
        openNotificationWithIcon("success", "Thêm danh mục tin tức thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thêm danh mục tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const UpdateCategoriesNews = createAsyncThunk(
  "categoryNewManage/UpdateCategoriesNews",
  async (params: { categoriesNew: CategoriesNew }, thunkAPI) => {
    try {
      const response = await CategoriesNewApis.UpdateCategoriesNews(params.categoriesNew);
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Cập nhật danh mục tin tức thành công",
          response.message
        );
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Cập nhật danh mục tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteCategoriesNewsByList = createAsyncThunk(
  "categoryNewManage/DeleteCategoriesNewsByList",
  async (params: { listId: string[] }, thunkAPI) => {
    try {
      const response = await CategoriesNewApis.DeleteCategoriesNewsByList(params.listId);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa danh mục tin tức thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa danh mục tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const HideCategoriesNewsByList = createAsyncThunk(
  "categoryNewManage/HideCategoriesNewsByList",
  async (params: { listId: string[]; check: boolean }, thunkAPI) => {
    try {
      const response = await CategoriesNewApis.HideCategoriesNewsByList(
        params.listId,
        params.check
      );
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Thông báo",
          response.message
        );
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const CategoryNew = createSlice({
  name: "CategoryNew",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCategoriesNewsAdmin
    [GetListCategoriesNewsAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoriesNewsAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoriesNewsAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListCategoriesNewsById
    [GetListCategoriesNewsById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoriesNewsById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
      state.apis.listPayload = [];
    },
    [GetListCategoriesNewsById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertCategoriesNews
    [InsertCategoriesNews.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertCategoriesNews.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertCategoriesNews.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateCategoriesNews
    [UpdateCategoriesNews.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateCategoriesNews.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [UpdateCategoriesNews.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteCategoriesNewsByList
    [DeleteCategoriesNewsByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteCategoriesNewsByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteCategoriesNewsByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region HideCategoriesNewsByList
    [HideCategoriesNewsByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [HideCategoriesNewsByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [HideCategoriesNewsByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region GetListCategoriesNewsClient
    [GetListCategoriesNewsClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoriesNewsClient.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoriesNewsClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
  },
});

export default CategoryNew.reducer;
