import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListRespone } from "../../../../models/commom";
import { LeaderShip } from "../../../../models/leaderShip";
import { openNotificationWithIcon } from "../../../../units";
import { LeaderShipApis } from "../../api/leaderShipApis";

export interface LeaderShipSliceState {
  loading: boolean;
  apis: ListRespone<LeaderShip>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: LeaderShipSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as LeaderShip,
  },
  btnLoading: false,
  postLength: 0,
};

export const GetListLeaderShipAdmin = createAsyncThunk(
  "LeaderShip/GetListLeaderShipAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await LeaderShipApis.GetListLeadershipAdmin(
        params.pageSize,
        params.pageNumber
      );
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon(
          "warning",
          "Lấy danh sách leader thất bại",
          response.message
        );
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon(
        "error",
        "Server Error",
        `${error.config.url} => ${error.message}`
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListLeadershipById = createAsyncThunk(
  "LeaderShip/GetListLeadershipById",
  async (params: { IdLeadership: string }, thunkAPI) => {
    try {
      const response = await LeaderShipApis.GetListLeadershipById(
        params.IdLeadership
      );
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon(
          "warning",
          "Lấy danh sách leader thất bại",
          response.message
        );
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon(
        "error",
        "Server Error",
        `${error.config.url} => ${error.message}`
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const InsertLeadership = createAsyncThunk(
  "LeaderShip/InsertLeadership",
  async (params: { leadership: Partial<LeaderShip> }, thunkAPI) => {
    try {
      const response = await LeaderShipApis.InsertLeadership(params.leadership);
      if (response.data.success) {
        openNotificationWithIcon(
          "success",
          "Thêm leader thành công",
          response.data.message
        );
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        openNotificationWithIcon(
          "warning",
          "Thêm leader thất bại",
          response.data.message
        );
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      openNotificationWithIcon(
        "error",
        "Server Error",
        `${error.config.url} => ${error.message}`
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const UpdateLeadership = createAsyncThunk(
  "LeaderShip/UpdateLeadership",
  async (params: { leadership: Partial<LeaderShip> }, thunkAPI) => {
    try {
      const response = await LeaderShipApis.UpdateLeadership(params.leadership);
      if (response.data.success) {
        openNotificationWithIcon(
          "success",
          "Cập nhật leader thành công",
          response.data.message
        );
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        openNotificationWithIcon(
          "warning",
          "Cập nhật leader thất bại",
          response.data.message
        );
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      openNotificationWithIcon(
        "error",
        "Server Error",
        `${error.config.url} => ${error.message}`
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteLeadership = createAsyncThunk(
  "LeaderShip/DeleteLeadership",
  async (params: { IdLeadership: string }, thunkAPI) => {
    try {
      const response = await LeaderShipApis.DeleteLeadership(
        params.IdLeadership
      );
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Xóa leader thành công",
          response.message
        );
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon(
          "warning",
          "Xóa leader thất bại",
          response.message
        );
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon(
        "error",
        "Server Error",
        `${error.config.url} => ${error.message}`
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const HideLeadership = createAsyncThunk(
  "LeaderShip/HideLeadership",
  async (params: { IdLeadership: string; check: boolean }, thunkAPI) => {
    try {
      const response = await LeaderShipApis.HideLeadership(
        params.IdLeadership,
        params.check
      );
      if (response.success) {
        openNotificationWithIcon(
          "success",
          "Thông báo",
          response.message
        );
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon(
          "warning",
          "Thông báo",
          response.message
        );
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon(
        "error",
        "Server Error",
        `${error.config.url} => ${error.message}`
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const LeaderShipSlice = createSlice({
  name: "LeaderShipSlice",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListLeadership
    [GetListLeaderShipAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListLeaderShipAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListLeaderShipAdmin.rejected.type]: (state) => {
      state.loading = true;
    },
    //#endregion
    //#region GetListLeadershipById
    [GetListLeadershipById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListLeadershipById.fulfilled.type]: (
      state,
      action: PayloadAction<ListRespone<LeaderShip>>
    ) => {
      state.loading = false;
      state.apis = action.payload;
      state.apis.listPayload = [];
    },
    [GetListLeadershipById.rejected.type]: (state) => {
      state.loading = true;
    },
    //#endregion
    //#region InsertLeadership
    [InsertLeadership.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertLeadership.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.apis = action.payload;
    },
    [InsertLeadership.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateLeadership
    [UpdateLeadership.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateLeadership.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.apis = action.payload;
    },
    [UpdateLeadership.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteLeadership
    [DeleteLeadership.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteLeadership.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteLeadership.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region HideLeadership
    [HideLeadership.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [HideLeadership.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [HideLeadership.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default LeaderShipSlice.reducer;
