import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListRespone } from "../../../../models/commom";
import { CorporatePartner } from "../../../../models/corporatePartner";
import { openNotificationWithIcon } from "../../../../units";
import { CorporatePartnerApis } from "../../api/corporatePartnerApis";

interface CorporatePartnerState {
  loading: boolean;
  apis: ListRespone<CorporatePartner>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: CorporatePartnerState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as CorporatePartner,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCorporatePartnerAdmin = createAsyncThunk(
  "CorporatePartner/GetListCorporatePartnerAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CorporatePartnerApis.GetListCorporatePartnerAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách đối tác thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetCorporatePartnerById = createAsyncThunk(
  "CorporatePartner/GetCorporatePartnerById",
  async (params: { IdCorporatePartner: string }, thunkAPI) => {
    try {
      const response = await CorporatePartnerApis.GetListCorporatePartnerById(params.IdCorporatePartner);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy đối tác thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const InsertCorporatePartner = createAsyncThunk(
  "CorporatePartner/InsertCorporatePartner",
  async (params: { data: CorporatePartner }, thunkAPI) => {
    try {
      const response = await CorporatePartnerApis.InsertCorporatePartner(params.data);
      if (response.data.success) {
        openNotificationWithIcon("success", "Thêm đối tác thành công", response.data.message);
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        openNotificationWithIcon("warning", "Thêm đối tác thất bại", response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const UpdateCorporatePartner = createAsyncThunk(
  "CorporatePartner/UpdateCorporatePartner",
  async (params: { data: CorporatePartner }, thunkAPI) => {
    try {
      const response = await CorporatePartnerApis.UpdateCorporatePartner(params.data);
      if (response.data.success) {
        openNotificationWithIcon("success", "Cập nhật đối tác thành công", response.data.message);
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        openNotificationWithIcon("warning", "Cập nhật đối tác thất bại", response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteCorporatePartnerByList = createAsyncThunk(
  "CorporatePartner/DeleteCorporatePartnerByList",
  async (params: { listId: string[] }, thunkAPI) => {
    try {
      const response = await CorporatePartnerApis.DeleteCorporatePartnerByList(params.listId);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa đối tác thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa đối tác thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const HideCorporatePartnerByList = createAsyncThunk(
  "CorporatePartner/HideCorporatePartnerByList",
  async (params: { listId: string[]; check: boolean }, thunkAPI) => {
    try {
      const response = await CorporatePartnerApis.HideCorporatePartnerByList(params.listId, params.check);
      if (response.success) {
        openNotificationWithIcon("success", "Cập nhật đối tác thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Cập nhật đối tác thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteCorporatePartner = createAsyncThunk(
  "CorporatePartner/DeleteCorporatePartner",
  async (params: { IdCorporatePartner: string }, thunkAPI) => {
    try {
      const response = await CorporatePartnerApis.DeleteCorporatePartner(params.IdCorporatePartner);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa đối tác thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa đối tác thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const HideCorporatePartner = createAsyncThunk(
  "CorporatePartner/HideCorporatePartner",
  async (params: { IdCorporatePartner: string; check: boolean }, thunkAPI) => {
    try {
      const response = await CorporatePartnerApis.HideCorporatePartner(params.IdCorporatePartner, params.check);
      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const CorporatePartnerSlice = createSlice({
  name: "CorporatePartner",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCorporatePartnerAdmin
    [GetListCorporatePartnerAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCorporatePartnerAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCorporatePartnerAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetCorporatePartnerById
    [GetCorporatePartnerById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetCorporatePartnerById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
      state.apis.listPayload = [];
    },
    [GetCorporatePartnerById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertCorporatePartner
    [InsertCorporatePartner.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertCorporatePartner.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertCorporatePartner.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateCorporatePartner
    [UpdateCorporatePartner.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateCorporatePartner.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.apis.listPayload = [];
    },
    [UpdateCorporatePartner.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteCorporatePartnerByList
    [DeleteCorporatePartnerByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteCorporatePartnerByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteCorporatePartnerByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region HideCorporatePartnerByList
    [HideCorporatePartnerByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [HideCorporatePartnerByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [HideCorporatePartnerByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteCorporatePartner
    [DeleteCorporatePartner.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteCorporatePartner.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteCorporatePartner.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region HideCorporatePartner
    [HideCorporatePartner.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [HideCorporatePartner.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [HideCorporatePartner.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default CorporatePartnerSlice.reducer;
