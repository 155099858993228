import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListRespone, PartialRequiredId } from "../../../../models/commom";
import { News } from "../../../../models/news";
import { Slide } from "../../../../models/slide";
import { openNotificationWithIcon } from "../../../../units";
import { NewsApis } from "../../api/newsApis";

interface NewsSliceState {
  loading: boolean;
  apis: ListRespone<News>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: NewsSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as News,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListNewsAdmin = createAsyncThunk(
  "News/GetListNewsAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await NewsApis.GetListNewsAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListNewsById = createAsyncThunk(
  "News/GetListNewsById",
  async (params: { IdNews: string }, thunkAPI) => {
    try {
      const response = await NewsApis.GetListNewsById(params.IdNews);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteNewsByList = createAsyncThunk(
  "News/DeleteNewsByList",
  async (params: { listId: string[] }, thunkAPI) => {
    try {
      const response = await NewsApis.DeleteNewsByList(params.listId);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa tin tức Thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const ActiveNewsByList = createAsyncThunk(
  "News/ActiveNewsByList",
  async (params: { listId: string[]; isActive: boolean }, thunkAPI) => {
    try {
      const response = await NewsApis.ActiveNewsByList(params.listId, params.isActive);
      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const UpdateNews = createAsyncThunk(
  "News/UpdateNews",
  async (params: { data: PartialRequiredId<News, "id"> }, thunkAPI) => {
    try {
      const response = await NewsApis.UpdateNews(params.data);
      if (response.success) {
        openNotificationWithIcon("success", "Cập nhật tin tức Thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Cập nhật tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const InsertNews = createAsyncThunk(
  "News/InsertNews",
  async (params: { news: Partial<Slide> }, thunkAPI) => {
    try {
      const response = await NewsApis.InsertNews(params.news);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thêm tin tức thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const SaveNews = createAsyncThunk(
  "News/SaveNews",
  async (params: { news: Partial<Slide> }, thunkAPI) => {
    try {
      const response = await NewsApis.SaveNews(params.news);
      if (response.data.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Cập nhật tin tức thất bại", response.data.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const NewsSlice = createSlice({
  name: "News",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListNewsAdmin
    [GetListNewsAdmin.pending.type]: (state, action) => {
      state.loading = true;
    },
    [GetListNewsAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListNewsAdmin.rejected.type]: (state, action) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListNewsById
    [GetListNewsById.pending.type]: (state, action) => {
      state.loading = true;
    },
    [GetListNewsById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
      state.apis.listPayload = [];
    },
    [GetListNewsById.rejected.type]: (state, action) => {
      state.loading = false;
    },
    //#endregion
    //#region DeleteNewsByList
    [DeleteNewsByList.pending.type]: (state, action) => {
      state.btnLoading = true;
    },
    [DeleteNewsByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength - 1;
    },
    [DeleteNewsByList.rejected.type]: (state, action) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region ActiveNewsByList
    [ActiveNewsByList.pending.type]: (state, action) => {
      state.btnLoading = true;
    },
    [ActiveNewsByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength - 1;
    },
    [ActiveNewsByList.rejected.type]: (state, action) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateNews
    [UpdateNews.pending.type]: (state, action) => {
      state.btnLoading = true;
    },
    [UpdateNews.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [UpdateNews.rejected.type]: (state, action) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region InsertNews
    [InsertNews.pending.type]: (state, action) => {
      state.btnLoading = true;
    },
    [InsertNews.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertNews.rejected.type]: (state, action) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default NewsSlice.reducer;
