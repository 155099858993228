import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Button, Card, Col, Divider, Popconfirm, Row, Space, Table, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { GetListMenuAdmin, HideMenuByList } from "../MenuSlice";
import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import { ModalContent } from "../../../components/ModalContent/ModalContent";
import { EditMenu } from "../components/EditMenu";


function _Menu() {
  const dispatch = useAppDispatch();
  const { apis, loading, btnLoading, postLength } = useAppSelector((state) => state.MenuSlice);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [record, setRecord] = useState();
  useEffect(() => {
    (async () => {
      try {
        await dispatch(GetListMenuAdmin({ pageSize: 0, pageNumber: 0 }));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [dispatch, postLength]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [{
    title: "Tên",
    dataIndex: "nameMenu",
    key: "nameMenu",
  },
    {
      title: "Code",
      dataIndex: "menuCode",
      key: "menuCode",
    },
    {
      title: "Trạng thái",
      dataIndex: "isHide",
      key: "isHide",
      render: (text: boolean) => (!text ? <Tag color="blue">Hiện</Tag> :
        <Tag color="warning">Ẩn</Tag>),
    },
    {
      title: "Sắp xếp",
      dataIndex: "number",
      key: "number",
    }, {
      title: "Thao tác",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => <Button icon={<EditOutlined />} type="primary" onClick={() => {
        setIsOpenEdit(true);
        setRecord(record);
      }}>Sửa</Button>,
    },
  ];
  return <div className="Menu">
    <ModalContent title={"Sửa menu"} width={"700px"} visible={isOpenEdit} setVisible={setIsOpenEdit}>
      <EditMenu record={record || {}} setVisible={setIsOpenEdit} />
    </ModalContent>
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Typography.Title level={3}>Quản lý Menu</Typography.Title>
          <Divider />
          <Space
            style={{
              marginBottom: 16,
            }}
            wrap
          >
            <Popconfirm
              title="Bạn có chắc chắn không ?"
              okText="Có"
              disabled={!(selectedRowKeys.length > 0)}
              cancelText="Không"
              onConfirm={() =>
                dispatch(
                  HideMenuByList({
                    listId: selectedRowKeys as string[],
                    IsHide: false,
                  }),
                )
              }
            >
              <Button
                type="primary"
                disabled={!(selectedRowKeys.length > 0)}
                ghost
                loading={btnLoading}
                icon={<CheckCircleOutlined />}
              >
                Hiện {selectedRowKeys.length} mục
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Bạn có chắc chắn không ?"
              okText="Có"
              disabled={!(selectedRowKeys.length > 0)}
              cancelText="Không"
              onConfirm={() =>
                dispatch(
                  HideMenuByList({
                    listId: selectedRowKeys as string[],
                    IsHide: true,
                  }),
                )
              }
            >
              <Button danger type="default" disabled={!(selectedRowKeys.length > 0)} loading={btnLoading}
                      icon={<StopOutlined />}>
                Ẩn {selectedRowKeys.length} mục
              </Button>
            </Popconfirm>
          </Space>
          <Table
            scroll={{
              x: "auto",
            }}
            rowKey={(record) => record.id}
            columns={columns}
            rowSelection={rowSelection}
            dataSource={apis.listPayload}
            loading={loading}
            bordered
            pagination={{
              total: apis.totalElament,
              showSizeChanger: true,
              showLessItems: true,

              pageSizeOptions: ["6", "10", "20", "50", "100"],
              showQuickJumper: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mục`,
            }}
          />
        </Card>
      </Col>
    </Row>;
  </div>;

};

export const Menu = WithErrorBoundaryCustom(_Menu);