import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { GetListCategoryProductionById, UpdateCategoryProduction } from "../CategoryProductSlice";
import { RcFile } from "antd/lib/upload";
import { ContactAndIntroductionApis } from "../../../api/contactAndIntroductionApis";
import { CategoryProduct } from "../../../../../models/categoryProduct";

function _EditCategoryProduct() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fromref = React.createRef<FormInstance>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { btnLoading, apis, loading } = useAppSelector((state) => state.CategoryProduct);
  useEffect(() => {
    if (params.id) {
      dispatch(GetListCategoryProductionById({ IdCategoryProduction: params.id as string })).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          setFileList([
            {
              uid: res.payload.payload.id,
              name: res.payload.payload.fileName as string,
              status: "done",
              url: ContactAndIntroductionApis.GetFileContactAndIntroduction(
                res.payload.payload.id + "." + res.payload.payload.fileNameExtention,
              ),
            },
          ]);
        }
      });
    }
  }, [dispatch, params.id]);
  useEffect(() => {
    fromref.current?.setFieldsValue(apis.payload);
  }, [apis.payload]);
  const onFinish = (values: CategoryProduct) => {
    const newData = new FormData();
    newData.append("id", apis.payload.id || (params.id as string));
    newData.append("NameProduct", values.nameProduct || "");
    newData.append("Description", values.description || "");
    newData.append("CodeProduct", values.codeProduct || "");
    if (fileList.length > 0) {
      newData.append("File", fileList[0].originFileObj as Blob);
    }
    dispatch(UpdateCategoryProduction({ categoryProduct: newData as any })).then((res: any) => {
      if (res.meta.requestStatus === "fulfilled") {
        // navigate("/admin/categoryProducts");
      }
    });
  };
  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      newFileList[0].response = "ok";
    }
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file?.originFileObj as RcFile);

        reader.onload = () => resolve(reader.result as string);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div className="layout-content EditCategoryProducts">
      <Spin spinning={loading}>
        <Skeleton loading={loading} active avatar paragraph={{ rows: 15 }}>
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <Typography.Title level={5}>Chỉnh sửa danh mục sản phẩm</Typography.Title>
                <Form
                  onFinish={onFinish}
                  layout={"vertical"}
                  ref={fromref}
                  className="ant-advanced-search-form"
                  name="UpdateCategoryProducts"
                  initialValues={apis.payload}
                >
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item label="Tên danh mục" name="nameProduct">
                        <Input.TextArea placeholder="Tên danh mục" showCount />
                      </Form.Item>
                      <Form.Item
                        label="Mã danh mục"
                        name="codeProduct"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập mã danh mục",
                          },
                        ]}
                      >
                        <Input.TextArea placeholder="Mã danh mục" showCount />
                      </Form.Item>
                      <Form.Item>
                        <Space direction="horizontal" wrap>
                          <Button type="primary" htmlType="submit" loading={btnLoading}>
                            Xác nhận
                          </Button>
                          <Button htmlType="button" loading={btnLoading} onClick={() => navigate(-1)}>
                            Quay lại
                          </Button>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Mô tả" name="description">
                        <Input.TextArea placeholder="Mô tả" showCount maxLength={151} rows={5} />
                      </Form.Item>
                      <Form.Item label="Hình ảnh" name="Files" help="Width: 370px, Heigth:270px">
                        <Upload
                          accept="image/png, image/jpeg, image/jpg"
                          action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                          name="Files"
                          listType="picture"
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={onPreview}
                          maxCount={1}
                        >
                          <Button icon={<UploadOutlined />}>Click để tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Skeleton>
      </Spin>
    </div>
  );
}

export const EditCategoryProduct = WithErrorBoundaryCustom(_EditCategoryProduct);
