import { CategoryServices } from "../../../models/categoryServices";
import { ListRespone, ListResponeFormData, Respone } from "../../../models/commom";
import { axiosClient } from "./axiosClient";

export const CategoryServicesApis = {
  GetListCategoryServiceAdmin: (pageNumber: number, pageSize: number): Promise<ListRespone<CategoryServices>> => {
    const url = `/CategoryService/GetListCategoryServiceAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListCategoryServiceClient: (pageNumber: number, pageSize: number): Promise<ListRespone<CategoryServices>> => {
    const url = `/CategoryService/GetListCategoryServiceClient?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axiosClient.get(url);
  },
  GetListCategoryServiceById: (IdCategoryService: string): Promise<ListRespone<CategoryServices>> => {
    const url = `/CategoryService/GetListCategoryServiceById?IdCategoryService=${IdCategoryService}`;
    return axiosClient.get(url);
  },
  InsertCategoryService: (categoryServices: CategoryServices): Promise<Respone> => {
    const url = `/CategoryService/InsertCategoryService`;
    return axiosClient.post(url, categoryServices);
  },
  UpdateCategoryService: (categoryServices: CategoryServices): Promise<Respone> => {
    const url = `/CategoryService/UpdateCategoryService`;
    return axiosClient.put(url, categoryServices);
  },
  HideCategoryServiceByList: (listId: string[], check: boolean): Promise<ListRespone<CategoryServices>> => {
    const url = `/CategoryService/HideCategoryServiceByList?check=${check}`;
    return axiosClient.put(url, listId);
  },
  DeleteCategoryServiceByList: (listId: string[]): Promise<ListRespone<CategoryServices>> => {
    const url = `/CategoryService/DeleteCategoryServiceByList`;
    return axiosClient.delete(url, { data: listId });
  },
} as const;
