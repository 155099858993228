import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListRespone } from "../../../../models/commom";
import { Contact } from "../../../../models/contact";

import { openNotificationWithIcon } from "../../../../units";
import { ContactApis } from "../../api/contactApis";

interface ContactSliceState {
  loading: boolean;
  apis: ListRespone<Contact>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: ContactSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as Contact,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListContactAdmin = createAsyncThunk(
  "Contact/GetListContactAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await ContactApis.GetListContactAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách liên hệ thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListContactById = createAsyncThunk(
  "Contact/GetListContactById",
  async (params: { IdContact: string }, thunkAPI) => {
    try {
      const response = await ContactApis.GetListContactById(params.IdContact);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách liên hệ thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteContactByList = createAsyncThunk(
  "Contact/DeleteContactByList",
  async (params: { listId: string[] }, thunkAPI) => {
    try {
      const response = await ContactApis.DeleteContactByList(params.listId);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa danh sách liên hệ thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa danh sách liên hệ thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const ContactSlice = createSlice({
  name: "Contact",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListContactAdmin
    [GetListContactAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListContactAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListContactAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region DeleteContactByList
    [DeleteContactByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteContactByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteContactByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default ContactSlice.reducer;
