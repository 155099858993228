import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserResponse } from "../models/user";
import { UserLoginRequest } from "../models/userAuth";
import { AuthApis } from "../package/admin/api/authApis";
import { UserApis } from "../package/admin/api/UserApis";
import { getCookie, openNotificationWithIcon, setCookie } from "../units";

export const getMe = createAsyncThunk("user/getMe", async (params, thunkAPI) => {
  try {
    if (getCookie("jwt")) {
      const response = await UserApis.GetUser();
      return thunkAPI.fulfillWithValue(response);
    }
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const userLogin = createAsyncThunk("user/login", async (params: UserLoginRequest, thunkAPI) => {
  try {
    const response = await AuthApis.Login(params);
    if (response.success) {
      if (response.roleList.find((item) => item.nameRole === "Admin")) {
        setCookie("jwt", response.data.accessToken, 1);
        openNotificationWithIcon("success", "Đăng nhập", response?.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Đăng nhập thất bại", response?.message);
        return thunkAPI.rejectWithValue(response);
      }
    } else {
      openNotificationWithIcon("warning", "Đăng nhập thất bại", response?.message);
      return thunkAPI.rejectWithValue(response);
    }
  } catch (err: any) {
    openNotificationWithIcon("error", "Server error", `${err.config.url} => ${err.message}`);
    return thunkAPI.rejectWithValue(err?.message);
  }
});

export const sendActiveCode = createAsyncThunk("user/sendActiveCode", async (params: string, thunkAPI) => {
  try {
    const response = await UserApis.SendCodeWithAccountActive(params);
    if (response.success) {
      return thunkAPI.fulfillWithValue(params);
    } else {
      openNotificationWithIcon("warning", "Gửi mã one time password(OTP) thất bại", response.message);
      return thunkAPI.rejectWithValue(response);
    }
  } catch (error: any) {
    openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
  }
});

export const confirmOTPCode = createAsyncThunk(
  "user/confirmOTPCode",
  async (params: { email: string; activeCode: string }, thunkAPI) => {
    try {
      const response = await UserApis.VerifyCode(params.email, params.activeCode);
      if (response.success) {
        openNotificationWithIcon("success", "Xác nhận OTP", response?.message);
        return thunkAPI.fulfillWithValue(params.email);
      } else {
        openNotificationWithIcon("warning", "Xác nhận OTP thất bại", response?.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
    }
  }
);

const userAuthSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    loggin: false,
    connect: false,
    user: null as UserResponse | null,
    error: null as string | null,
    isOpenForgotPassword: false,
    isOpenConfirmOTPCode: false,
    email: "",
  },
  reducers: {
    CancelConfirmOTPCode(state) {
      state.isOpenConfirmOTPCode = false;
    },
    CancelForgotPassword(state) {
      state.isOpenForgotPassword = false;
    },
  },
  extraReducers: {
    [getMe.pending.type]: (state) => {
      state.loading = true;
    },
    [getMe.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.connect = true;
      state.user = action.payload;
    },
    [getMe.rejected.type]: (state, action) => {
      state.loading = false;
      state.user = action.payload.response.data;
      state.connect = false;
      state.error = action.payload as string;
    },
    [userLogin.fulfilled.type]: (state) => {
      state.loggin = true;
      state.loading = false;
    },
    [sendActiveCode.fulfilled.type]: (state, action) => {
      state.isOpenConfirmOTPCode = true;
      state.email = action.payload;
    },
    [confirmOTPCode.fulfilled.type]: (state, action) => {
      state.isOpenForgotPassword = true;
      state.email = action.payload;
    },
  },
});

export default userAuthSlice;
