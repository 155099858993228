import { Button, Result } from "antd";
import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { AboutUs, EditAboutUs, NewAboutUs } from "../package/admin/features/aboutUs";
import { EditCategoryAboutUs } from "../package/admin/features/categoryAboutUs/components/EditCategoryAboutUs";
import { NewCategoryAboutUs } from "../package/admin/features/categoryAboutUs/components/NewCategoryAboutUs";
import { CategoryAboutUs } from "../package/admin/features/categoryAboutUs/page/CategoryAboutUs";
import { PrivateRoutes, PublicRoutes } from "../package/admin/routes";
import { CategoryProduct, EditCategoryProduct, NewCategoryProduct } from "../package/admin/features/categoryProduct";
import { EditInforProduct, InforProduct, NewInforProduct } from "../package/admin/features/inforProduct";
import { Menu } from "../package/admin/features/Menu";

const Main = lazy(() =>
  import("../package/admin/components").then((module) => ({
    default: module.Main,
  })),
);
const UserManage = lazy(() =>
  import("../package/admin/features/userManage").then((module) => ({
    default: module.UserManage,
  })),
);
const NewUser = lazy(() =>
  import("../package/admin/features/userManage/components/NewUser").then((module) => ({
    default: module.NewUser,
  })),
);
const EditUser = lazy(() =>
  import("../package/admin/features/userManage/components/EditUser").then((module) => ({
    default: module.EditUser,
  })),
);
const EditImage = lazy(() =>
  import("../package/admin/features/slideManage/components/EditImage").then((module) => ({
    default: module.EditImage,
  })),
);
const NewImage = lazy(() =>
  import("../package/admin/features/slideManage/components/NewImage").then((module) => ({
    default: module.NewImage,
  })),
);
const EditNews = lazy(() =>
  import("../package/admin/features/news/components/EditNews").then((module) => ({
    default: module.EditNews,
  })),
);
const NewNews = lazy(() =>
  import("../package/admin/features/news/components/NewNews").then((module) => ({
    default: module.NewNews,
  })),
);
const SlideManage = lazy(() =>
  import("../package/admin/features/slideManage").then((module) => ({
    default: module.SlideManage,
  })),
);
const News = lazy(() =>
  import("../package/admin/features/news").then((module) => ({
    default: module.News,
  })),
);
const EditLeaderShip = lazy(() =>
  import("../package/admin/features/leaderShip/components/EditLeaderShip").then((module) => ({
    default: module.EditLeaderShip,
  })),
);
const NewLeaderShip = lazy(() =>
  import("../package/admin/features/leaderShip/components/NewLeaderShip").then((module) => ({
    default: module.NewLeaderShip,
  })),
);
const LeaderShip = lazy(() =>
  import("../package/admin/features/leaderShip").then((module) => ({
    default: module.LeaderShip,
  })),
);
const EditInforServices = lazy(() =>
  import("../package/admin/features/inforServices/components/EditInforServices").then((module) => ({
    default: module.EditInforServices,
  })),
);
const NewInforServices = lazy(() =>
  import("../package/admin/features/inforServices/components/NewInforServices").then((module) => ({
    default: module.NewInforServices,
  })),
);
const InforServices = lazy(() =>
  import("../package/admin/features/inforServices").then((module) => ({
    default: module.InforServices,
  })),
);
const DashBoardLayout = lazy(() =>
  import("../package/admin/features/dashBoard/page/DashBoardLayout").then((module) => ({
    default: module.DashBoardLayout,
  })),
);
const NewCorporatePartner = lazy(() =>
  import("../package/admin/features/corporatePartner/components/NewCorporatePartner").then((module) => ({
    default: module.NewCorporatePartner,
  })),
);
const EditCorporatePartner = lazy(() =>
  import("../package/admin/features/corporatePartner/components/EditCorporatePartner").then((module) => ({
    default: module.EditCorporatePartner,
  })),
);
const CorporatePartner = lazy(() =>
  import("../package/admin/features/corporatePartner").then((module) => ({
    default: module.CorporatePartner,
  })),
);
const NewContactAndIntroduction = lazy(() =>
  import("../package/admin/features/contactAndIntroduction/components/NewContactAndIntroduction").then((module) => ({
    default: module.NewContactAndIntroduction,
  })),
);
const EditContactAndIntroduction = lazy(() =>
  import("../package/admin/features/contactAndIntroduction/components/EditContactAndIntroduction").then((module) => ({
    default: module.EditContactAndIntroduction,
  })),
);
const ContactAndIntroduction = lazy(() =>
  import("../package/admin/features/contactAndIntroduction").then((module) => ({
    default: module.ContactAndIntroduction,
  })),
);
const Contact = lazy(() =>
  import("../package/admin/features/contact").then((module) => ({
    default: module.Contact,
  })),
);
const NewCategoryServices = lazy(() =>
  import("../package/admin/features/categoryService/components/NewCategoryServices").then((module) => ({
    default: module.NewCategoryServices,
  })),
);
const CategoryServices = lazy(() =>
  import("../package/admin/features/categoryService").then((module) => ({
    default: module.CategoryServices,
  })),
);
const EditCategoryServices = lazy(() =>
  import("../package/admin/features/categoryService/components/EditCategoryServices").then((module) => ({
    default: module.EditCategoryServices,
  })),
);
const NewCategoryNew = lazy(() =>
  import("../package/admin/features/categoryNew/components/NewCategoryNew").then((module) => ({
    default: module.NewCategoryNew,
  })),
);
const EditCategoryNew = lazy(() =>
  import("../package/admin/features/categoryNew/components/EditCategoryNew").then((module) => ({
    default: module.EditCategoryNew,
  })),
);
const CategoryNew = lazy(() =>
  import("../package/admin/features/categoryNew").then((module) => ({
    default: module.CategoryNew,
  })),
);
const NewCareer = lazy(() =>
  import("../package/admin/features/career/components/NewCareer").then((module) => ({
    default: module.NewCareer,
  })),
);
const EditCareer = lazy(() =>
  import("../package/admin/features/career/components/EditCareer").then((module) => ({
    default: module.EditCareer,
  })),
);
const Career = lazy(() =>
  import("../package/admin/features/career").then((module) => ({
    default: module.Career,
  })),
);
const LoginAdminLayout = lazy(() =>
  import("../package/admin/features/auth/page/LoginAdminLayout").then((module) => ({
    default: module.LoginAdminLayout,
  })),
);

const styleLoading = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "100",
};

export function Admin() {
  const navigate = useNavigate();
  const { loggin } = useAppSelector((state) => state.userAuthSlice);
  const [reRender, setReRender] = useState(false);
  useEffect(() => {
    setReRender((prev) => !prev);
  }, [loggin]);
  const path = useLocation();
  return (
    <Suspense fallback={<div style={styleLoading}>loading..</div>}>
      {path.pathname.length === 1 ? (
        <Routes>
          <Route path="/" element={<PublicRoutes />}>
            <Route path="/" element={<LoginAdminLayout />} />
          </Route>
        </Routes>
      ) : path.pathname.includes("/Print") ? (
        <Routes>
          <Route path="/Print" element={<PrivateRoutes />}>
            <Route index path="/Print/PrintLibraryCard/:id" element={<></>} />
          </Route>

          <Route
            path="/Print-authorized-403"
            element={
              <Result
                status="403"
                title="403"
                subTitle="Xin lỗi, bạn không được phép truy cập trang này."
                extra={
                  <Button
                    type="primary"
                    onClick={(e) => {
                      navigate("/");
                    }}
                  >
                    Đăng nhập {reRender && ""}
                  </Button>
                }
              />
            }
          />
        </Routes>
      ) : (
        <Main>
          <Route path="/admin" element={<PrivateRoutes />}>
            <Route index path="/admin/dashboard" element={<DashBoardLayout />} />

            <Route path="/admin/quan-ly-nguoi-dung" element={<UserManage />} />
            <Route path="/admin/quan-ly-nguoi-dung/new" element={<NewUser />} />
            <Route path="/admin/quan-ly-nguoi-dung/edit/:idUser" element={<EditUser />} />

            <Route path="/admin/slide" element={<SlideManage />} />
            <Route path="/admin/slide/new" element={<NewImage />} />
            <Route path="/admin/slide/edit/:id" element={<EditImage />} />

            <Route path="/admin/leaderShip" element={<LeaderShip />} />
            <Route path="/admin/leaderShip/new" element={<NewLeaderShip />} />
            <Route path="/admin/leaderShip/edit/:id" element={<EditLeaderShip />} />

            <Route path="/admin/corporatePartner" element={<CorporatePartner />} />
            <Route path="/admin/corporatePartner/new" element={<NewCorporatePartner />} />
            <Route path="/admin/corporatePartner/edit/:id" element={<EditCorporatePartner />} />

            <Route path="/admin/contactAndIntroduction" element={<ContactAndIntroduction />} />
            <Route path="/admin/contactAndIntroduction/new" element={<NewContactAndIntroduction />} />
            <Route path="/admin/contactAndIntroduction/edit/:id" element={<EditContactAndIntroduction />} />

            <Route path="/admin/news" element={<News />} />
            <Route path="/admin/news/new" element={<NewNews />} />
            <Route path="/admin/news/edit/:id" element={<EditNews />} />

            <Route path="/admin/categoryNews" element={<CategoryNew />} />
            <Route path="/admin/categoryNews/new" element={<NewCategoryNew />} />
            <Route path="/admin/categoryNews/edit/:id" element={<EditCategoryNew />} />

            <Route path="/admin/contact" element={<Contact />} />

            <Route path="/admin/career" element={<Career />} />
            <Route path="/admin/career/new" element={<NewCareer />} />
            <Route path="/admin/career/edit/:id" element={<EditCareer />} />

            <Route path="/admin/categoryServices" element={<CategoryServices />} />
            <Route path="/admin/categoryServices/new" element={<NewCategoryServices />} />
            <Route path="/admin/categoryServices/edit/:id" element={<EditCategoryServices />} />

            <Route path="/admin/inforServices" element={<InforServices />} />
            <Route path="/admin/inforServices/new" element={<NewInforServices />} />
            <Route path="/admin/inforServices/edit/:id" element={<EditInforServices />} />

            <Route path="/admin/categoryProduct" element={<CategoryProduct />} />
            <Route path="/admin/categoryProduct/new" element={<NewCategoryProduct />} />
            <Route path="/admin/categoryProduct/edit/:id" element={<EditCategoryProduct />} />

            <Route path="/admin/inforProduct" element={<InforProduct />} />
            <Route path="/admin/inforProduct/new" element={<NewInforProduct />} />
            <Route path="/admin/inforProduct/edit/:id" element={<EditInforProduct />} />

            <Route path="/admin/categoryAboutUs" element={<CategoryAboutUs />} />
            <Route path="/admin/categoryAboutUs/new" element={<NewCategoryAboutUs />} />
            <Route path="/admin/categoryAboutUs/edit/:id" element={<EditCategoryAboutUs />} />

            <Route path="/admin/AboutUs" element={<AboutUs />} />
            <Route path="/admin/AboutUs/new" element={<NewAboutUs />} />
            <Route path="/admin/AboutUs/edit/:id" element={<EditAboutUs />} />

            <Route path="/admin/Menu" element={<Menu />} />
          </Route>

          {/* 404 not found */}
          <Route
            path="*"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Button
                    type="primary"
                    onClick={(e) => {
                      navigate("/");
                    }}
                  >
                    Back Home
                  </Button>
                }
              />
            }
          />
        </Main>
      )}
    </Suspense>
  );
}
