import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListRespone } from "../../../../models/commom";
import { InforServices } from "../../../../models/inforServices";
import { Slide } from "../../../../models/slide";
import { openNotificationWithIcon } from "../../../../units";
import { InforServicesApis } from "../../api/inforServicesApis";
import { NewsApis } from "../../api/newsApis";

interface InforServicesSliceState {
  loading: boolean;
  apis: ListRespone<InforServices>;
  btnLoading: boolean;
  postLength: number;
}
const initialState: InforServicesSliceState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as InforServices,
  },
  btnLoading: false,
  postLength: 0,
};

export const GetListInforServiceAdmin = createAsyncThunk(
  "InforProduct/GetListInforServiceAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await InforServicesApis.GetListInforServiceAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách thông tin lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListInforServiceClient = createAsyncThunk(
  "InforProduct/GetListInforServiceClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await InforServicesApis.GetListInforServiceClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách thông tin lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const GetListInforServiceById = createAsyncThunk(
  "InforProduct/GetListInforServiceById",
  async (params: { IdInforService: string }, thunkAPI) => {
    try {
      const response = await InforServicesApis.GetListInforServiceById(params.IdInforService);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy thông tin lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const InsertInforService = createAsyncThunk(
  "InforProduct/InsertInforService",
  async (params: { inforService: Partial<InforServices> }, thunkAPI) => {
    try {
      const response = await InforServicesApis.InsertInforService(params.inforService);
      if (response.success) {
        openNotificationWithIcon("success", "Thêm thông tin lĩnh vực hoạt động thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thêm thông tin lĩnh vực hoạt độngthất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const UpdateInforService = createAsyncThunk(
  "InforProduct/UpdateInforService",
  async (params: { inforService: Partial<InforServices> }, thunkAPI) => {
    try {
      const response = await InforServicesApis.UpdateInforService(params.inforService);
      if (response.success) {
        openNotificationWithIcon("success", "Cập nhật thông tin lĩnh vực hoạt động thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Cập nhật thông tin lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const DeleteInforServiceByList = createAsyncThunk(
  "InforProduct/DeleteInforServiceByList",
  async (params: { listId: string[] }, thunkAPI) => {
    try {
      const response = await InforServicesApis.DeleteInforServiceByList(params.listId);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa thông tin lĩnh vực hoạt động thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa thông tin lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const ActiveInforServiceByList = createAsyncThunk(
  "InforProduct/ActiveInforServiceByList",
  async (params: { listId: string[]; check: boolean }, thunkAPI) => {
    try {
      const response = await InforServicesApis.ActiveInforServiceByList(params.listId, params.check);
      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const SaveInforService = createAsyncThunk(
  "InforProduct/SaveInforService",
  async (params: { inforService: File }, thunkAPI) => {
    try {
      const response = await InforServicesApis.SaveInforService(params.inforService);
      if (response.data.success) {
        openNotificationWithIcon("success", "Lưu thông tin lĩnh vực hoạt động thành công", response.data.message);
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        openNotificationWithIcon("warning", "Lưu thông tin lĩnh vực hoạt độngthất bại", response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const InforServicesSlice = createSlice({
  name: "InforServices",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListInforServiceAdmin
    [GetListInforServiceAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforServiceAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListInforServiceAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListInforServiceClient
    [GetListInforServiceClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforServiceClient.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListInforServiceClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListInforServiceById
    [GetListInforServiceById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListInforServiceById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
      state.apis.listPayload = [];
    },
    [GetListInforServiceById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertInforService
    [InsertInforService.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertInforService.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertInforService.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateInforService
    [UpdateInforService.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateInforService.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [UpdateInforService.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteInforServiceByList
    [DeleteInforServiceByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteInforServiceByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteInforServiceByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region ActiveInforServiceByList
    [ActiveInforServiceByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [ActiveInforServiceByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [ActiveInforServiceByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default InforServicesSlice.reducer;
