import { useState, useEffect } from "react";
import "braft-editor/dist/index.css";
import BraftEditor from "braft-editor";
import WithErrorBoundaryCustom from "../../../../units/errorBounDary/WithErrorBoundaryCustom";

function _BraftRichEditor({
  setHtmlContent,
  language,
  initHtmlContent = `<p>hello <b>world!</b></p>`,
}: {
  setHtmlContent: (html: string) => void;
  language: "tr" | "zh" | "zh-hant" | "en" | "ru" | "jpn" | "kr" | "pl" | "fr" | "vi-vn";
  initHtmlContent?: string;
}) {
  const [state, setState] = useState({ editorState: BraftEditor.createEditorState(initHtmlContent) });
  useEffect(() => {
    setState({ editorState: BraftEditor.createEditorState(initHtmlContent) });
  }, [initHtmlContent]);
  const handleChange = (editorState: any) => {
    setState({ editorState });
  };
  useEffect(() => {
    const async = async () => {
      setHtmlContent(await state.editorState.toHTML());
    };
    async();
  }, [state, setHtmlContent]);
  
  return (
    <div className="BraftEditor">
      <BraftEditor value={state.editorState} onChange={handleChange} language={language} />
    </div>
  );
}
/**
 *
 * @param {function} setHtmlContent - funtion set State html content
 * @param {string} language - language of editor
 * @returns Html content
 */
export const BraftRichEditor = WithErrorBoundaryCustom(_BraftRichEditor);
