import { Badge, Button, Card, Col, Form, FormInstance, Input, Row, Select, Skeleton, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { aboutUs } from "../../../../../models/aboutUs";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { BraftRichEditor } from "../../../components/BraftEditor";
import { createMarkup } from "../../../components/modalViewContentHtml";
import { InfoCircleOutlined } from "@ant-design/icons";
import {  InsertAboutUs } from "../AboutUsSlice";
import { GetListCategoryAboutUsForInsertAboutUs } from "../../categoryAboutUs";


function _NewAboutUs() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, btnLoading } = useAppSelector((state) => state.AboutUs);
  const { loading: loadingCategoryAboutUs, apis } = useAppSelector((state) => state.CategoryAboutUs);
  const [preview, setPreview] = useState("");
  const convertedContentRef = React.useRef("");
  const fromref = React.createRef<FormInstance>();
  useEffect(() => {
    dispatch(GetListCategoryAboutUsForInsertAboutUs({ pageNumber: 0, pageSize: 0 }));
  }, [dispatch]);
  const GetHtmlContent = (html: string) => {
    convertedContentRef.current = html;
  };
  const onFinish = (values: aboutUs) => {
    values.contentAboutUs = convertedContentRef.current;
    dispatch(InsertAboutUs({ AboutUs: values })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate("/admin/AboutUs");
      }
    });
  };
  return (
    <div className="NewAboutUs Rich-editor">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <Typography.Title level={5}>Thêm mới về chúng tôi</Typography.Title>
            <Form onFinish={onFinish} layout={"vertical"} className="ant-advanced-search-form" name="normal_login"
                  ref={fromref}>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label="Tiêu đề" name="titleAboutUs">
                    <Input.TextArea placeholder="Tiêu đề" showCount />
                  </Form.Item>
                  <Form.Item label="Mô tả" name="description">
                    <Input.TextArea placeholder="Mô tả" showCount />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" loading={btnLoading} htmlType="submit">
                      Lưu lại
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Phân loại theo danh mục"
                    name="idCategorieAboutUs"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng phân loại nội dung",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={loadingCategoryAboutUs}
                      optionFilterProp="children"
                    >
                      {apis.listPayload?.map((item) => (
                        <Select.Option value={item.id}>
                          {item.nameAboutUs} - {item.codeAboutUs}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Status"
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn trạng thái",
                      },
                      {
                        pattern: new RegExp("^[0-9]*$"),
                        message: "Vui lòng nhập số",
                      },
                    ]}
                    tooltip={{
                      title: "Status = 1: hiển thị ra 3 cột trong trang về chúng tôi\n" +
                        "Status = 0: hiền thị ra nội dung bên dưới"
                      , icon: <InfoCircleOutlined />,
                    }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <Skeleton loading={loading} active avatar paragraph={{ rows: 10 }}>
            <Card bordered={false} className="criclebox h-full">
              <header className="App-header">Viết nội dung bài viết</header>
              <Space direction="vertical" size={"large"}>
                <Card bordered={true} className="criclebox h-full">
                  <BraftRichEditor language="en" setHtmlContent={GetHtmlContent} />
                </Card>
                <Button onClick={() => setPreview(convertedContentRef.current)} type="primary">
                  Xem trước nội dung
                </Button>
              </Space>
            </Card>
          </Skeleton>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <Skeleton loading={loading} active avatar paragraph={{ rows: 15 }}>
            <Badge.Ribbon color={"blue"} text="Xem trước">
              <Card bordered={false} className="criclebox h-full">
                <div className="preview" dangerouslySetInnerHTML={createMarkup(preview || "")}></div>
              </Card>
            </Badge.Ribbon>
          </Skeleton>
        </Col>
      </Row>
    </div>
  );
}

export const NewAboutUs = WithErrorBoundaryCustom(_NewAboutUs);
