import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MenuDto } from "../../../../models/MenuDto";
import { ListRespone } from "../../../../models/commom";
import { MenuApis } from "../../api/MenuApis";


interface MenuState {
  loading: boolean;
  apis: ListRespone<MenuDto>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: MenuState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as MenuDto,
    totalElament: 0,
  },
  btnLoading: false,
  postLength: 0,
};

export const GetListMenuClient = createAsyncThunk(
  "Menu/GetListMenuClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await MenuApis.GetListMenuClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListMenuAdmin = createAsyncThunk(
  "Menu/GetListMenuAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await MenuApis.GetListMenuAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetMenuById = createAsyncThunk(
  "Menu/GetMenuById",
  async (params: { IdMenu: string }, thunkAPI) => {
    try {
      const response = await MenuApis.GetMenuById(params.IdMenu);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const UpdateMenu = createAsyncThunk(
  "Menu/UpdateMenu",
  async (params: { menu: Partial<MenuDto> }, thunkAPI) => {
    try {
      const response = await MenuApis.UpdateMenu(params.menu);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  });
export const HideMenuByList = createAsyncThunk(
  "Menu/HideMenuByList",
  async (params: { listId: string[]; IsHide: boolean }, thunkAPI) => {
    try {
      const response = await MenuApis.HideMenuByList(params.listId, params.IsHide);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const MenuSlice = createSlice({
  name: "Menu",
  initialState,
  reducers: {},
  extraReducers: {
    [GetListMenuClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListMenuClient.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListMenuClient.rejected.type]: (state) => {
      state.loading = false;
    },
    [GetListMenuAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListMenuAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListMenuAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    [GetMenuById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetMenuById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis.payload = action.payload;
    },
    [GetMenuById.rejected.type]: (state) => {
      state.loading = false;
    },
    [UpdateMenu.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateMenu.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [UpdateMenu.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    [HideMenuByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [HideMenuByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [HideMenuByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
  },
});
export default MenuSlice.reducer;