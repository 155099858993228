import { Button, Card, Col, Form, Input, Row, Skeleton, Spin, Typography, UploadFile } from "antd";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import Upload, { RcFile, UploadProps } from "antd/lib/upload";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { CategoryAboutUs } from "../../../../../models/categoryAboutUs";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { InsertCategoryAboutUs } from "../CategoryAboutUsSlice";

function _NewCategoryAboutUs() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { btnLoading } = useAppSelector((state) => state.CategoryNew);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const onFinish = (values: CategoryAboutUs) => {
    const newData = new FormData();
    newData.append("status", values.status);
    newData.append("NameAboutUs", values.nameAboutUs || "");
    newData.append("Description", values.description || "");
    newData.append("CodeAboutUs", values.codeAboutUs || "");
    if (fileList.length > 0) {
      newData.append("File", fileList[0].originFileObj as Blob);
    }
    dispatch(InsertCategoryAboutUs({ categoryAboutUs: newData as any })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate("/admin/categoryAboutUs");
      }
    });
  };
  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      newFileList[0].response = "ok";
    }
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file?.originFileObj as RcFile);

        reader.onload = () => resolve(reader.result as string);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div className="layout-content NewCategoryAboutUs">
      <Spin spinning={false}>
        <Skeleton loading={false} active avatar paragraph={{ rows: 15 }}>
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <Typography.Title level={5}>Thêm danh mục về chúng tôi</Typography.Title>
                <Form onFinish={onFinish} layout={"vertical"} className="ant-advanced-search-form"
                      name="insertCategoryAboutUs">
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item label="Tên danh mục" name="nameAboutUs">
                        <Input.TextArea placeholder="Tên danh mục" showCount />
                      </Form.Item>
                      <Form.Item
                        label="Mã danh mục"
                        name="codeAboutUs"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập mã danh mục",
                          },
                        ]}
                      >
                        <Input.TextArea placeholder="Mã danh mục" showCount />
                      </Form.Item>
                      <Form.Item
                        label="Status"
                        name="status"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn trạng thái",
                          },
                          {
                            pattern: new RegExp("^[0-9]*$"),
                            message: "Vui lòng nhập số",
                          },
                        ]}
                        tooltip={{
                          title: "Status = 1: hiển thị lên thanh header\n" +
                            "Status = 0: hiền thị ra body"
                          , icon: <InfoCircleOutlined />,
                        }}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" loading={btnLoading} htmlType="submit">
                          Lưu lại
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Mô tả" name="description">
                        <Input.TextArea placeholder="Mô tả" showCount maxLength={151} rows={5} />
                      </Form.Item>
                      <Form.Item label="Hình ảnh" name="Files" help="Width: 370px, Heigth:270px">
                        <Upload
                          accept="image/png, image/jpeg, image/jpg"
                          action={"https://www.mocky.io/v2/5cc8019d300000980a055e76"}
                          name="Files"
                          listType="picture"
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={onPreview}
                          maxCount={1}
                        >
                          <Button icon={<UploadOutlined />}>Click để tải lên</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Skeleton>
      </Spin>
    </div>
  );
}

export const NewCategoryAboutUs = WithErrorBoundaryCustom(_NewCategoryAboutUs);
