import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CategoryProduct } from "../../../../models/categoryProduct";
import { ListRespone } from "../../../../models/commom";
import { openNotificationWithIcon } from "../../../../units";
import { CategoryProductApis } from "../../api/categoryProductApis";

export interface CategoryProductState {
  loading: boolean;
  apis: ListRespone<CategoryProduct>;
  btnLoading: boolean;
  postLength: number;
}

const initialState: CategoryProductState = {
  loading: false,
  apis: {
    listPayload: [],
    payload: {} as CategoryProduct,
  },
  btnLoading: false,
  postLength: 0,
};
export const GetListCategoryProductionAdmin = createAsyncThunk(
  "CategoryProduct/GetListCategoryProductionAdmin",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoryProductApis.GetListCategoryProductionAdmin(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListCategoryProductionClient = createAsyncThunk(
  "CategoryProduct/GetListCategoryProductionClient",
  async (params: { pageSize: number; pageNumber: number }, thunkAPI) => {
    try {
      const response = await CategoryProductApis.GetListCategoryProductionClient(params.pageSize, params.pageNumber);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const GetListCategoryProductionById = createAsyncThunk(
  "CategoryProduct/GetListCategoryProductionById",
  async (params: { IdCategoryProduction: string }, thunkAPI) => {
    try {
      const response = await CategoryProductApis.GetListCategoryProductionById(params.IdCategoryProduction);
      if (response.success) {
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Lấy danh sách danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const InsertCategoryProduction = createAsyncThunk(
  "CategoryProduct/InsertCategoryProduction",
  async (params: { categoryProduct: CategoryProduct }, thunkAPI) => {
    try {
      const response = await CategoryProductApis.InsertCategoryProduction(params.categoryProduct);
      if (response.success) {
        openNotificationWithIcon("success", "Thêm danh mục lĩnh vực hoạt động thanh công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thêm danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const UpdateCategoryProduction = createAsyncThunk(
  "CategoryProduct/UpdateCategoryProduction",
  async (params: { categoryProduct: CategoryProduct }, thunkAPI) => {
    try {
      const response = await CategoryProductApis.UpdateCategoryProduction(params.categoryProduct);
      if (response.success) {
        openNotificationWithIcon("success", "Cập nhật danh mục lĩnh vực hoạt động thanh công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Cập nhật danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const HideCategoryProductionByList = createAsyncThunk(
  "CategoryProduct/HideCategoryProductionByList",
  async (params: { listId: string[]; check: boolean }, thunkAPI) => {
    try {
      const response = await CategoryProductApis.HideCategoryProductionByList(params.listId, params.check);
      if (response.success) {
        openNotificationWithIcon("success", "Thông báo", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Thông báo", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const DeleteCategoryProductionByList = createAsyncThunk(
  "CategoryProduct/DeleteCategoryProductionByList",
  async (params: { listId: string[] }, thunkAPI) => {
    try {
      const response = await CategoryProductApis.DeleteCategoryProductionByList(params.listId);
      if (response.success) {
        openNotificationWithIcon("success", "Xóa danh mục lĩnh vực hoạt động thành công", response.message);
        return thunkAPI.fulfillWithValue(response);
      } else {
        openNotificationWithIcon("warning", "Xóa danh mục lĩnh vực hoạt động thất bại", response.message);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error: any) {
      openNotificationWithIcon("error", "Server Error", `${error.config.url} => ${error.message}`);
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const CategoryProductSlice = createSlice({
  name: "CategoryProduct",
  initialState,
  reducers: {},
  extraReducers: {
    //#region GetListCategoryProductionAdmin
    [GetListCategoryProductionAdmin.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryProductionAdmin.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryProductionAdmin.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListCategoryProductionClient
    [GetListCategoryProductionClient.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryProductionClient.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryProductionClient.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region GetListCategoryProductionById
    [GetListCategoryProductionById.pending.type]: (state) => {
      state.loading = true;
    },
    [GetListCategoryProductionById.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.apis = action.payload;
    },
    [GetListCategoryProductionById.rejected.type]: (state) => {
      state.loading = false;
    },
    //#endregion
    //#region InsertCategoryProduction
    [InsertCategoryProduction.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [InsertCategoryProduction.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [InsertCategoryProduction.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region UpdateCategoryProduction
    [UpdateCategoryProduction.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [UpdateCategoryProduction.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
    },
    [UpdateCategoryProduction.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region HideCategoryProductionByList
    [HideCategoryProductionByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [HideCategoryProductionByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [HideCategoryProductionByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
    //#region DeleteCategoryProductionByList
    [DeleteCategoryProductionByList.pending.type]: (state) => {
      state.btnLoading = true;
    },
    [DeleteCategoryProductionByList.fulfilled.type]: (state, action) => {
      state.btnLoading = false;
      state.postLength = state.postLength + 1;
    },
    [DeleteCategoryProductionByList.rejected.type]: (state) => {
      state.btnLoading = false;
    },
    //#endregion
  },
});

export default CategoryProductSlice.reducer;
