import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { getMe } from "./app/userAuthSlice";
import { fetchDataUserList } from "./package/admin/features/userManage";
import { Admin } from "./Routes";
import { openNotificationWithIcon } from "./units";


function App()
{
  const dispatch = useAppDispatch();
  const { loggin } = useAppSelector(state => state.userAuthSlice);

  //update useEffect when window location reload


  useEffect(() =>
  {
    async function Authenticate()
    {
      try
      {
        await dispatch(getMe());
      } catch (err)
      {
        openNotificationWithIcon("error", "Failed to login", err.message);
      }
    }
    Authenticate();
  }, [dispatch, loggin]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Admin />
  )
}

export default App;
