import { Button, Col, Form, Input, Row, Spin } from "antd";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { useEffect } from "react";
import { UpdateMenu } from "../MenuSlice";
import { MenuDto } from "../../../../../models/MenuDto";

interface IProps {
  record: Partial<MenuDto>;
  setVisible: (visible: boolean) => void;
}

function _EditMenu(Props: IProps) {
  const dispatch = useAppDispatch();
  const { record, setVisible } = Props;
  const [form] = Form.useForm();
  const { loading } = useAppSelector((state) => state.MenuSlice);
  useEffect(() => {
    form.setFieldsValue(record);
  }, [form, record]);
  const onFinish = async (values: any) => {
    try {
      const res = await dispatch(UpdateMenu({ menu: values }));
      if (res.meta.requestStatus === "fulfilled") {
        setVisible(false);
        form.resetFields();
      }

    } catch (e) {
      console.log(e);
    }

  };

  return <div className="EditMenu">
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Spin spinning={loading}>
          <Form form={form} onFinish={onFinish} layout={"vertical"}>
            <Form.Item name={"id"} hidden={true} />
            <Form.Item label="Tên" name={"nameMenu"} rules={[{
              required: true,
              message: "Vui lòng nhập tên menu",
            }]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Sắp xếp" name={"number"} rules={[
              {
                required: true,
                message: "Vui lòng nhập thứ tự sắp xếp",
              },
              {
                //check is number
                pattern: new RegExp(/^[0-9\b]+$/),
                message: "Vui lòng nhập số",
              },
            ]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType={"submit"}>Lưu</Button>
            </Form.Item>
          </Form>
        </Spin>
      </Col>
    </Row>
  </div>;
};
export const EditMenu = WithErrorBoundaryCustom(_EditMenu);