import React from "react";
import { ListRespone } from "../../../models/commom";
import { User, UserResponse } from "../../../models/user";
import { getCookie } from "../../../units";
import { axiosClient, axiosClientFormData } from "./axiosClient";

export const UserApis = {
  GetListUser(): Promise<ListRespone<User>> {
    const url = `/User/GetListUser`;
    return axiosClient.get(url);
  },
  GetUserById(id: string): Promise<ListRespone<User>> {
    const url = `/User/GetUerById?IdUser=${id}`;
    return axiosClient.get(url);
  },
  GetUser(): Promise<UserResponse> {
    const url = `/User/GetUser`;
    return axiosClient.get(url, {
      headers: {
        Authorization: `Bearer ${getCookie("jwt")}`,
      },
    });
  },
  Delete(id: string): Promise<ListRespone<User>> {
    const url = `/User/DeleteUser?Id=${id}`;
    return axiosClient.delete(url);
  },
  RemoveUserByList(ListId: React.Key[]): Promise<ListRespone<User>> {
    const url = `/User/RemoveUserByList`;
    return axiosClient.delete(url, { data: ListId });
  },
  LockUserAccount(id: string, isLock: boolean): Promise<ListRespone<User>> {
    const url = `/User/LockUserAccount?Id=${id}&isLock=${isLock}`;
    return axiosClient.put(url);
  },
  LockUserAccountByList(ListId: React.Key[], isLock: boolean): Promise<ListRespone<User>> {
    const url = `/User/LockUserAccountByList?isLock=${isLock}`;
    return axiosClient.put(url, ListId);
  },
  UpdateUser(user: Partial<User>): Promise<ListRespone<User>> {
    const url = `/User/UpdateUser`;
    return axiosClientFormData.put(url, user);
  },
  CreateUser(user: Partial<User>): Promise<ListRespone<User>> {
    const url = `/User/InsertUser`;
    return axiosClientFormData.post(url, user);
  },
  AddRoleUser(idUser: string, idRole: string): Promise<ListRespone<User>> {
    const url = `/User/AddRoleUser`;
    return axiosClient.post(url, { idUser, idRole });
  },
  DeleteUserRole(idUser: string, idRole: string): Promise<ListRespone<User>> {
    const url = `/User/DeleteUserRole`;
    return axiosClient.post(url, { idUser, idRole });
  },

  ActiveUserByCode(email: string, code: string): Promise<ListRespone<User>> {
    const url = `/User/ActiveUserByCode?code=${code}&email=${email}`;
    return axiosClient.put(url);
  },
  SendAgainCode(email: string): Promise<ListRespone<User>> {
    const url = `/User/SendAgainCode?email=${email}`;
    return axiosClient.put(url);
  },
  SendCodeWithAccountActive(email: string): Promise<ListRespone<User>> {
    const url = `/User/SendCodeWithAccountActive?email=${email}`;
    return axiosClient.put(url);
  },
  VerifyCode(email: string, code: string): Promise<ListRespone<User>> {
    const url = `/User/VerifyCode?email=${email}&code=${code}`;
    return axiosClient.post(url);
  },
  ForgotPassword(email: string, newPassword: string): Promise<ListRespone<User>> {
    const url = `/User/ForgotPassword?email=${email}&newPassword=${newPassword}`;
    return axiosClient.put(url);
  },
  ChangePassword(email: string, oldPassword: string, newPassword: string): Promise<ListRespone<User>> {
    const url = `/User/ChangePassword?email=${email}&oldPassword=${oldPassword}&newPassword=${newPassword}`;
    return axiosClient.put(url);
  },
} as const;
