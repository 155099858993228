import {
  BarsOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
} from "antd";
import { ColumnsType, FilterValue } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import WithErrorBoundaryCustom from "../../../../../units/errorBounDary/WithErrorBoundaryCustom";
import moment from "moment";
import { InforProduct as InforProductModel } from "../../../../../models/inforProduct";
import { ModalViewContentHtml } from "../../../components/modalViewContentHtml";
import { GetListCategoryServiceAdmin } from "../../categoryService";
import {
  ActiveInforProductionByList,
  DeleteInforProductionByList,
  GetListInforProductionAdmin,
} from "../InforProductSlice";
import { GetListCategoryProductionAdmin } from "../../categoryProduct";

function _InforProduct() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<{
    title: string;
    content: string;
  }>();
  const { loading, apis, btnLoading, postLength } = useAppSelector((state) => state.InforProduct);
  const {
    loading: loadingCategoryService,
    apis: apisCategoryProduct,
  } = useAppSelector((state) => state.CategoryProduct);
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  useEffect(() => {
    dispatch(
      GetListInforProductionAdmin({
        pageSize: 0,
        pageNumber: 0,
      }),
    );
    dispatch(
      GetListCategoryProductionAdmin({
        pageSize: 0,
        pageNumber: 0,
      }),
    );
  }, [dispatch, postLength]);

  const handleChange: TableProps<InforProductModel>["onChange"] = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };
  const onSelectChange = (InforProductelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(InforProductelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const menuAction = (record: InforProductModel) => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          icon={<EditOutlined />}
          onClick={() => {
            navigate(`/admin/InforProduct/edit/${record.id}`);
          }}
        >
          Chỉnh sửa
        </Menu.Item>
      </Menu>
    );
  };

  const columns: ColumnsType<InforProductModel> = [
    {
      title: "Tiêu đề",
      dataIndex: "titleProduct",
      key: "titleProduct",
      filters: apis.listPayload?.map((item) => ({
        text: item.titleProduct,
        value: item.titleProduct,
      })),
      filteredValue: filteredInfo?.titleService || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.titleProduct.startsWith(value),
    },
    {
      title: "Nội dung",
      dataIndex: "contentProduct",
      key: "contentProduct",
      render: (text, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => {
            setModalContent({
              title: `Nội dung - ${record.titleProduct}`,
              content: text,
            });
            setIsModalOpen(true);
          }}
        >
          Xem
        </Button>
      ),
    },
    {
      title: "Người đăng",
      dataIndex: "postBy",
      key: "postBy",
      filters: apis.listPayload?.map((item) => ({
        text: item.postBy,
        value: item.postBy,
      })),
      filteredValue: filteredInfo?.postBy || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.postBy.startsWith(value),
    },
    {
      title: "Danh mục",
      dataIndex: "idCategoryProduct",
      key: "idCategoryProduct",
      filters: apisCategoryProduct.listPayload?.map((item) => ({
        text: `${item.nameProduct} - ${item.codeProduct}`,
        value: item.id,
      })),
      filteredValue: filteredInfo?.idCategoryProduct || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.idCategoryProduct.startsWith(value),

      render: (text, record) => {
        const category = apisCategoryProduct.listPayload.find((item) => item.id === text);
        return `${category?.nameProduct} - ${category?.codeProduct}`;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "isActive",
      key: "isActive",
      filters: apis.listPayload?.map((item) => ({
        text: item.isActive,
        value: item.isActive,
      })),
      filteredValue: filteredInfo?.isActived || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.isActive === value,
      render: (isActived) => (isActived ? <Tag color="blue">Đã kích hoạt</Tag> :
        <Tag color="warning">Chưa kich hoạt</Tag>),
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      filters: apis.listPayload?.map((item) => ({
        text: moment(item.createdDate).format("DD-MM-YYYY HH:mm:ss"),
        value: moment(item.createdDate).format("DD-MM-YYYY HH:mm:ss"),
      })),
      filteredValue: filteredInfo?.createdDate || null,
      filterSearch: true,
      onFilter: (value: any, record) => record.createdDate.toString().startsWith(value),
      render: (text) => moment(text).format("DD-MM-YYYY HH:mm:ss"),
    },
    {
      title: "Thao tác",
      dataIndex: "Action",
      key: "Action",
      fixed: "right",
      render: (_, record) => (
        <Dropdown overlay={menuAction(record)} trigger={["click"]}>
          <BarsOutlined style={{ fontSize: 24 }} />
        </Dropdown>
      ),
      width: 10,
    },
  ];

  return (
    <div className="InforProduct">
      <ModalViewContentHtml isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalContent={modalContent} />

      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <Typography.Title level={3}>Sản phẩm </Typography.Title>
            <Divider />
            <Space
              style={{
                marginBottom: 16,
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  setSelectedRowKeys([]);
                }}
                disabled={!(selectedRowKeys.length > 0)}
                loading={loading}
              >
                Bỏ chọn {selectedRowKeys.length} mục
              </Button>

              <Button
                onClick={(e) => navigate("/admin/InforProduct/new")}
                icon={[
                  <svg
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1743"
                    width="12"
                    height="12"
                  >
                    <path d="M32 96l960 0 0 64-960 0 0-64z" p-id="1744"></path>
                    <path d="M32 864l960 0 0 64-960 0 0-64z" p-id="1745"></path>
                    <path d="M128 192l64 0 0 64-64 0 0-64z" p-id="1746"></path>
                    <path d="M224 192l64 0 0 64-64 0 0-64z" p-id="1747"></path>
                    <path d="M320 192l64 0 0 64-64 0 0-64z" p-id="1748"></path>
                    <path d="M384 768l-256 0 0-320 256 0 0 320zM192 704l128 0 0-192-128 0 0 192z" p-id="1749"></path>
                    <path d="M448 448l224 0 0 64-224 0 0-64z" p-id="1750"></path>
                    <path d="M1024 352l-1024 0 0-256 1024 0 0 256zM64 288l896 0 0-128-896 0 0 128z" p-id="1751"></path>
                    <path d="M1024 928l-1024 0 0-479.84 64 0 0 415.84 896 0 0-447.264 64 0z" p-id="1752"></path>
                    <path d="M448 576l448 0 0 64-448 0 0-64z" p-id="1753"></path>
                    <path d="M448 704l448 0 0 64-448 0 0-64z" p-id="1754"></path>
                  </svg>,
                ]}
                size="small"
                type="default"
              >
                Thêm mới sản phẩm
              </Button>
              <Popconfirm
                title="Bạn có chắc chắn không ?"
                okText="Có"
                disabled={!(selectedRowKeys.length > 0)}
                cancelText="Không"
                onConfirm={() =>
                  dispatch(
                    ActiveInforProductionByList({
                      listId: selectedRowKeys as string[],
                      check: true,
                    }),
                  )
                }
              >
                <Button
                  type="primary"
                  disabled={!(selectedRowKeys.length > 0)}
                  ghost
                  loading={btnLoading}
                  icon={<CheckCircleOutlined />}
                >
                  Kích hoạt {selectedRowKeys.length} mục
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Bạn có chắc chắn không ?"
                okText="Có"
                disabled={!(selectedRowKeys.length > 0)}
                cancelText="Không"
                onConfirm={() =>
                  dispatch(
                    ActiveInforProductionByList({
                      listId: selectedRowKeys as string[],
                      check: false,
                    }),
                  )
                }
              >
                <Button danger type="default" disabled={!(selectedRowKeys.length > 0)} loading={btnLoading}
                        icon={<StopOutlined />}>
                  Khóa {selectedRowKeys.length} mục
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Bạn có chắc chắn không ?"
                okText="Có"
                cancelText="Không"
                disabled={!(selectedRowKeys.length > 0)}
                onConfirm={() => {
                  dispatch(
                    DeleteInforProductionByList({
                      listId: selectedRowKeys as string[],
                    }),
                  );
                  setSelectedRowKeys([]);
                }}
              >
                <Button danger type="primary" loading={btnLoading} disabled={!(selectedRowKeys.length > 0)}
                        icon={<DeleteOutlined />}>
                  Xóa {selectedRowKeys.length} mục
                </Button>
              </Popconfirm>
            </Space>
            <Table
              scroll={{
                x: "auto",
              }}
              rowKey={(record) => record.id}
              columns={columns}
              rowSelection={rowSelection}
              dataSource={apis.listPayload}
              onChange={handleChange}
              loading={loading}
              pagination={{
                total: apis.totalElament,
                showSizeChanger: true,
                showLessItems: true,

                pageSizeOptions: ["6", "10", "20", "50", "100"],
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} mục`,
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export const InforProduct = WithErrorBoundaryCustom(_InforProduct);
